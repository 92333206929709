/*
createdBy:
createdAt:
email:
*/
import {
  bulkUpsertDocument,
  projectIdReadDocument,
  projectReadDocument,
  readDocument,
  saveSchema,
} from "../../function/commonapi";
import { v4 as uuidv4 } from "uuid";
import config from "../../config";
import { paletteList } from "../../utils/constants";
import de from "date-fns/esm/locale/de/index.js";
import axios from "axios";

export const handleSubmitProject = (state) => {
  //debugger;
  return new Promise(async (resolve, reject) => {
    let {
      client_id,
      tools,
      projectName,
      language,
      themeType,
      palette,
      availableFonts,
      masterTypograpy,
      users,
      keyValues,
      projectPlatform,
      databaseName,
      uploadImages,
      addproject,
      headerName,
      client_key,
      gitBinderUrl,
      gitUiUrl,
      validationError,
    } = state;
    let dbnameCheck = {
      _id: client_id,
    };
    //debugger;
    let db_projectname = await projectReadDocument(dbnameCheck);
    if (db_projectname?.data.Code === 201) {
      let checkProjectName =
        db_projectname?.data?.result?.length > 0
          ? db_projectname?.data?.result?.some(
            (_) => _.projectname === projectName?.trim()
          )
          : false;
      if (checkProjectName) {
        reject({
          error: true,
          msg: "project name already exiest",
        });
        validationError = true;
        // validationError = false
      }
    } else {
      reject({
        error: true,
        msg: "project name validation faild",
      });
    }
    let dbparams = {
      entity: "projects",
    };
    let dbNameData = await readDocument(dbparams);
    if (dbNameData?.data.Code === 201) {
      let checkDatabase =
        dbNameData?.data?.result?.length > 0
          ? dbNameData?.data?.result?.some(
            (_) => _.projectdbname === databaseName?.trim()
          )
          : false;
      if (checkDatabase) {
        reject({
          error: true,
          msg: "database name already exiest",
        });
        validationError = true;
        // validationError = false
      }
    } else {
      reject({
        error: true,
        msg: "database name validation faild",
      });
    }
    projectPlatform = projectPlatform.map((_) => _.value);
    language = language.map((_) => _.value);
    users = users.map((_) => _.value);
    availableFonts = availableFonts.map((_) => _.value);
    let [
      Background,
      Text,
      Primary,
      Secondary,
      Error,
      Warning,
      Success,
      Divider,
    ] = palette;
    let projectParams = {
      projectid: uuidv4(),
      projectname: projectName.trim(),
      projectfiles: uploadImages,
      projectpaltforms: projectPlatform,
      projectlang: language,
      projectusers: users,
      projectkeyvalues: keyValues,
      projectfonts: availableFonts,
      active: true,
      projectdbname: databaseName.trim(),
      createdby: "",
      activestatus: true,
      gitBinderUrl: gitBinderUrl,
      gitUiUrl: gitUiUrl,
    };
    let projectPlattle = {
      projectId: "",
      palettetype: themeType,
      bg: Background.props,
      text: Text.props,
      primary: Primary.props,
      secondary: Secondary.props,
      error: Error.props,
      warning: Warning.props,
      success: Success.props,
      divider: Divider.props,
      active: true,
      createdby: "",
      activestatus: true,
    };

    let sentProjectApiParams = {
      db_name: config.database,
      entity: "projects",
      is_metadata: false,
      doc: {
        ...projectParams,
      },
    };
    try {
      let project_hit = await bulkUpsertDocument([sentProjectApiParams]);
      if (project_hit?.data?.Code === 201) {
        //debugger;
        let _project_id = project_hit.data.Result[0].properties.doc._id;
        projectPlattle.projectId = _project_id;
        let typographyApiParams = masterTypograpy.map((_) => {
          return {
            db_name: config.database,
            entity: "projecttypography",
            is_metadata: false,
            doc: {
              projectId: _project_id,
              typographyid: _._id,
              typofonts: _.typofonts[0]?._id ?? "",
              fontsize: _.fontsize,
              fontweight: _.fontweight?._id ?? "",
              linehgt:
                typeof _.linehgt === "number" ? String(_.linehgt) : _.linehgt,
              ltrspace:
                typeof _.ltrspace === "number"
                  ? String(_.ltrspace)
                  : _.ltrspace,
              active: true,
              createdby: "",
              activestatus: true,
            },
          };
        });
        let projectPaletteApiParams = {
          db_name: config.database,
          entity: "projectpalette",
          is_metadata: false,
          doc: {
            ...projectPlattle,
          },
        };
        let clientProjectEdge = {
          db_name: config.database,
          entity: "client_project_E",
          is_metadata: false,
          doc: {
            _from: client_id,
            _to: _project_id,
          },
        };
        let apiCallData = [projectPaletteApiParams, clientProjectEdge];
        let toolsApiCall = tools.map((_) => {
          return {
            db_name: config.database,
            entity: "projectvstools",
            is_metadata: false,
            doc: {
              toolid: _._id,
              projectid: _project_id,
              clientid: client_id,
              metadataid: uuidv4(),
              metadataname:
                projectName +
                "_" +
                _.toolname
                  .split(" ")
                  .filter((_) => _.length > 0)
                  .join("_"),
              dbname: databaseName,
              active: true,
              createdby: "",
              activestatus: true,
            },
          };
        });
        apiCallData = [...apiCallData, ...toolsApiCall];
        apiCallData = [...apiCallData, ...typographyApiParams];
        //debugger;
        let project_palate_typo_hit = await bulkUpsertDocument(apiCallData);
        if (project_palate_typo_hit?.data?.Code !== 201) {
          reject({
            error: true,
            msg: "document uploading faild",
          });
        }
        let default_collection_payload = {
          entity: "default_collection",
          filter: "default_collection.active==true",
        };

        let defaultCollection = await readDocument(default_collection_payload);
        if (defaultCollection?.data?.Code === 201) {
          let collectionList = defaultCollection?.data?.result?.map((_c) => {
            return _c.schema;
          });

          // entity designer tool id = tools/138547259
          let project_vs_tool = {
            entity: "projectvstools",
            filter: `projectvstools.projectid=='${_project_id}' and projectvstools.toolid=='tools/138547259'`,
          };
          let client_info = await readDocument(project_vs_tool);
          if (client_info?.data?.Code === 201) {
            let save_schema_params = {
              client: headerName[1],
              db_name: databaseName,
              clientId: client_key,
              metadataId: client_info?.data?.result[0]?.metadataid,
              metadataname: client_info?.data?.result[0]?.metadataname,
              attributes: [
                {
                  relationship: [],
                  listOfCollapse: [],
                },
              ],
              metadata: collectionList,
              entity_relationship: [],
              projectId: projectParams.projectid,
              metadata_dbname: config.database,
            };
            let createDatabase = await saveSchema(save_schema_params);
            if (createDatabase?.data?.Code === 201) {

              let generaldata = [
                {
                  "db_name": databaseName,
                  "entity": "CodeableConceptMaster",
                  "metadata_dbname": config.database,
                  "is_metadata": true,
                  "metadataId": client_info?.data?.result[0]?.metadataid,
                  "doc": [
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT07",
                          "display": "Numeric",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "Numeric",
                          "status": true,
                          "gmconfigvalues": {
                            "_key": "10735"
                          }
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT05",
                          "display": "Text Box",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "Text Box",
                          "status": true,
                          "gmconfigvalues": {
                            "_key": "10591"
                          }
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT01",
                          "display": "date",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "date",
                          "status": true,
                          "gmconfigvalues": {
                            "_key": "10587"
                          }
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT06",
                          "display": "Boolean",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "Boolean",
                          "status": true,
                          "gmconfigvalues": {}
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT03",
                          "display": "Checkbox",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "Checkbox",
                          "status": true,
                          "gmconfigvalues": {
                            "_key": "10589"
                          }
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT02",
                          "display": "select",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "select",
                          "status": true,
                          "gmconfigvalues": {
                            "_key": "10588"
                          }
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT04",
                          "display": "switch",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "switch",
                          "status": true,
                          "gmconfigvalues": {
                            "_key": "10590"
                          }
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    },
                    {
                      "text": "",
                      "coding": [
                        {
                          "system": "",
                          "version": "1",
                          "code": "DT08",
                          "display": "List",
                          "userSelected": true,
                          "id": 0,
                          "Type": "QDMDATATYPE",
                          "shortdesc": "List",
                          "status": true,
                          "gmconfigvalues": {}
                        }
                      ],
                      "id": 0,
                      "Type": "QDMDATATYPE",
                      "status": true
                    }
                  ]
                }
              ]
              var configfile = {
                method: "post",
                url: `${config.entity_api_url}/api/upsert_document`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: generaldata,
              };

              await axios(configfile)
                .then((response) => {
                })
                .catch((err) => { });

              resolve({
                error: false,
                msg: "project created successfully",
                data: {
                  clientId: client_id,
                  addproject: !addproject,
                },
              });
            } else {
              reject({
                error: true,
                msg: "somthing went wrong in database creation ! ",
              });
            }
          }
        } else {
          reject({
            error: true,
            msg: "somthing went wrong in database creation ! ",
          });
        }
      } else {
        reject({
          error: true,
          msg: "somthing went wrong in project creation ! ",
        });
      }
    } catch (error) {
      reject({
        error: true,
        msg: "somthing went wrong in project creation ! ",
      });
    }
  });
};

export const handleUpdateProject = (state) => {
  return new Promise(async (resolve, reject) => {
    let {
      client_id,
      projectName,
      language,
      themeType,
      palette,
      availableFonts,
      masterTypograpy,
      users,
      keyValues,
      projectPlatform,
      uploadImages,
      addproject,
      _idOfproject,
      projectPaletteId,
      gitBinderUrl,
      gitUiUrl,
    } = state;
    let dbnameCheck = {
      _id: client_id,
    };
    let db_projectname = await projectReadDocument(dbnameCheck);
    if (db_projectname?.data.Code === 201) {
      let checkProjectName =
        db_projectname?.data?.result?.length > 0
          ? db_projectname?.data?.result?.some(
            (_) =>
              _._id !== _idOfproject && _.projectname === projectName?.trim()
          )
          : false;
      if (checkProjectName) {
        reject({
          error: true,
          msg: "project name already exiest",
        });
      }
    } else {
      reject({
        error: true,
        msg: "project name validation faild",
      });
    }
    projectPlatform = projectPlatform.map((_) => _.value);
    language = language.map((_) => _.value);
    users = users.map((_) => _.value);
    availableFonts = availableFonts.map((_) => _.value);
    let [
      Background,
      Text,
      Primary,
      Secondary,
      Error,
      Warning,
      Success,
      Divider,
    ] = palette;
    let projectParams = {
      projectname: projectName,
      projectfiles: uploadImages,
      projectpaltforms: projectPlatform,
      projectlang: language,
      projectusers: users,
      projectkeyvalues: keyValues,
      projectfonts: availableFonts,
      gitBinderUrl: gitBinderUrl,
      gitUiUrl: gitUiUrl,
      updatedby: "",
    };
    let projectPlattle = {
      palettetype: themeType,
      bg: Background.props,
      text: Text.props,
      primary: Primary.props,
      secondary: Secondary.props,
      error: Error.props,
      warning: Warning.props,
      success: Success.props,
      divider: Divider.props,
      updatedby: "",
    };

    let sentProjectApiParams = {
      db_name: config.database,
      entity: "projects",
      filter: { _id: _idOfproject },

      is_metadata: false,
      doc: {
        ...projectParams,
      },
    };
    let typographyApiParams = masterTypograpy.map((_) => {
      return {
        db_name: config.database,
        entity: "projecttypography",
        filter: { _id: _.projectTypoId },

        is_metadata: false,
        doc: {
          typofonts: _.typofonts[0]?._id ?? "",
          fontsize: _.fontsize,
          fontweight: _.fontweight?._id ?? "",
          linehgt:
            typeof _.linehgt === "number" ? String(_.linehgt) : _.linehgt,
          ltrspace:
            typeof _.ltrspace === "number" ? String(_.ltrspace) : _.ltrspace,
          updatedby: "",
        },
      };
    });
    let projectPaletteApiParams = {
      db_name: config.database,
      entity: "projectpalette",
      filter: { _id: projectPaletteId },
      is_metadata: false,
      doc: {
        ...projectPlattle,
      },
    };
    let compainArray = [sentProjectApiParams, projectPaletteApiParams];
    compainArray = [...compainArray, ...typographyApiParams];
    try {
      let project_hit = await bulkUpsertDocument(compainArray);
      if (project_hit?.data?.Code === 201) {
        // //debugger;
        resolve({
          error: false,
          msg: "project updated successfully ! ",
          data: {
            clientId: client_id,
            addproject: !addproject,
            anchorEl: null,
          },
        });
      } else {
        reject({
          error: true,
          msg: "project updating failded",
        });
      }
    } catch (error) {
      reject({
        error: true,
        msg: "project updating failded",
      });
    }
  });
};
export const handleFetchProjectEditData = async (state, projectId) => {
  return new Promise(async (resolve, reject) => {
    // let state = state;
    let params = {
      id: projectId,
    };
    await projectIdReadDocument(params)
      .then((response) => {
        if (response?.data?.Code !== 201) {
          reject({
            error: true,
            msg: "project fetching data faild",
          });
        }

        let result = response?.data?.result[0] ?? {};
        if (response?.data?.result.length > 0) {
          result = {
            ...result.projects,
            projectpalette: result.projectpalette,
            projecttypography: result.projecttypography,
          };
        }
        state._idOfproject = result._id;
        state.projectPlatform = state.masterProjectPlatform.filter((_) =>
          result.projectpaltforms.some((_s) => _s._id == _._id)
        );
        state.language = state.masterLanguage.filter((_) =>
          result.projectlang.some((_s) => _s._id == _._id)
        );
        state.users = state.masterUsers.filter((_) =>
          result.projectusers.some((_s) => _s._id == _._id)
        );
        state.availableFonts = state.masterFontFamily.filter((_) =>
          result.projectfonts.some((_s) => _s._id == _._id)
        );
        state.databaseName = result?.projectdbname ?? "";
        state.uploadImages = result.projectfiles;
        state.projectName = result.projectname;
        state.gitBinderUrl = result?.gitBinderUrl;
        state.gitUiUrl = result.gitUiUrl;
        state.keyValues = result.projectkeyvalues;
        let projectPalette = result?.projectpalette[0];
        state.themeType = projectPalette.palettetype;
        let palette_list = paletteList;
        state.projectPaletteId = projectPalette._id;
        state.palette = palette_list.map((_) => {
          if (_.label.toLocaleLowerCase() == "background") {
            _.props = projectPalette.bg;
          } else {
            _.props = projectPalette[_.label.toLocaleLowerCase()] ?? [];
          }
          return _;
        });
        // //debugger;
        state.masterTypograpy = state.cloneMasterTypograpy.map((_) => {
          let fil_proj_typo = result.projecttypography.filter(
            (_i) => _i?.typographyid?._id === _._id
          );
          fil_proj_typo = fil_proj_typo?.length > 0 ? fil_proj_typo[0] : {};
          if (fil_proj_typo?._id) {
            _.fontsize = fil_proj_typo?.fontsize ?? "";
            _.linehgt = fil_proj_typo?.linehgt ?? "";
            _.ltrspace = fil_proj_typo?.ltrspace ?? "";
            _.typofonts = fil_proj_typo?.typofonts
              ? state.masterFontFamily.filter(
                (_) => fil_proj_typo?.typofonts?._id == _._id
              )
              : [];
            let modify_fontWeight = fil_proj_typo?.fontweight
              ? state.masterFontWeight.filter(
                (_) => fil_proj_typo?.fontweight?._id == _._id
              )
              : [{}];
            _.fontweight = modify_fontWeight[0];
            _.projectTypoId = fil_proj_typo._id;
          }
          return _;
        });
        state.addproject = true;
        state.is_update = true;
        state.activeStep = 0;
        resolve({
          error: false,
          msg: "project fetching data successfully",
          data: state,
        });
      })
      .catch((error) => {
        reject({
          error: true,
          msg: "somthing went wrong ! ",
        });
      });
  });
};
