import React from 'react'
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom'
import routes from './routes'
import PrivateRoute from "./private_router";
import {
  Login,
  
  Home,

} from './../screens'

const RouterApp = props => {
  return (
    <Router>
      <Switch>
        {/* redirect page component(login) */}
        <Route exact path='/' >
             {sessionStorage.getItem('payload') ? <Redirect to="/home" /> : <Redirect to="/login" />}
        </Route>
        {/* end */}
        {/* login component */}
        <Route path={routes.login} component={Login} />
        {/* end */}
        {/* entity component */}
        <PrivateRoute path={routes.home} component={Home} />
        

        {/* end */}
        {/* ainqa component */}
        <PrivateRoute path={routes.ainqa} component={Home} />
        {/* end */}
        {/* MessageCatalog Component */}
        {/* <Route path={routes.messageCatalog} component={MessageCatalog} /> */}
        {/* end */}
        {/* <PrivateRoute path={routes.repository} component={Repository} /> */}
      </Switch>
    </Router>
  )
}

export default RouterApp
