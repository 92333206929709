/*
createdBy:
createdAt:
email:
*/
import {
  projectPaletteInfo,
  tabNameValueforclients,
  validationFieldForProject,
} from "../../utils/constants";

export const stateProperties = {
  addproject: false,
  addprojectvalue: "",
  theme: "",
  data: [],
  isopen: false,
  snackmsg: "",
  snacktype: "",
  tab: 0,
  tabIndex: 0,
  qpasData: [],
  addclient: false,
  clientList: [],
  listClient: [],
  status: "client",
  projectTools: [],
  projectToolLists: [],
  headerName: [],
  projectId: "",
  metaDataId: "",
  databaseName: "",
  metaDataName: "",
  userdetails: {},
  clientDrawer: false,
  userDrawer: false,
  username: "",
  email: "",
  designation: "",
  userError: {
    username: false,
    email: false,
  },
  addclientvalue: "",
  clientError: {
    addclientvalue: false,
  },
  clientErrorMsg: {
    addclientvalue: "Please enter the field",
  },
  deleteDrawer: false,

  deleteClient: false,
  deleteClientId: "",
  projectName: "",
  enableEdit: false,
  editProjectId: "",
  anchorEl: null,
  activeStep: 0,
  tabValue: 0,
  themeSwitcher: false,
  selectedFont: false,
  fontFamily: [],
  client_id: "",
  tools: [],
  project_vs_tools: [],
  masterProjectPlatform: [],
  masterLanguage: [],
  projectName: "",
  deleteProject: false,
  language: [],
  themeType: "light",
  client_key: "",
  palette: projectPaletteInfo,
  availableFonts: [],
  masterTypograpy: [],
  cloneMasterTypograpy: [],
  masterFontWeight: [],
  masterFontFamily: [],
  users: [],
  masterUsers: [],
  keyValues: [
    {
      key: "",
      value: "",
    },
  ],
  projectPlatform: [],
  databaseName: "",
  uploadImages: [],
  _idOfproject: "",
  projectPaletteId: "",
  validatation: validationFieldForProject,
  is_update: false,
  validationError: false,
  datavalid: false,
  projectvalid: false,
  gitUiUrl: "",
  gitBinderUrl: "",
  clientname: "",
  currentclientid: "",
  currentprojectid: "",
  currentIdmtype: "",
  currentroleid: "",
  currentIDMdata: {},
  idmdata: "",
  dynamiclistgenerator_keyvalue: "",
  headeridmdata: {},
  isclientzero: false,
  isprojectzero: false,
  istoolszero: false,
  idmbinded_tabvalue: [],
  isfallback: false,
};
