/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Typography, Grid, Divider } from "@material-ui/core";

import { withAllContexts } from "../../hocs";
import styles from "./styles";
import {
  readDocument,
  deleteDocument,
  upsertDocument,
} from "../../function/commonapi";
import config from "../../config";
import DeleteComponent from "../deleteComponent/deleteComponent";
import Tree from "../tree";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "atp-idm-binder";
import environment from "../../config";
import { stateProperties } from "../entityComponent/stateProperties";
import { datacontructor } from "../../function/commonfunction";
import { Zero_ofclient_project_fallbackpage_message } from "../../utils/constants";
import FallbackScreenofIDM from "../../screens/FallbackScreenOfIDM";

function RepositoryMain(props) {
  const { parentnameoftree, repodata, whichidm } = props;
  const classes = styles();

  const [pageData, setPageData] = useState([]);
  const [zeroFallback, setZeroFallback] = React.useState({
    show: true,
    message: "",
  });
  React.useEffect(() => {
    // debugger;
    var localshow = true;
    Object.keys(Zero_ofclient_project_fallbackpage_message).forEach((v) => {
      // debugger;
      if (stateProperties[v] === true) {
        localshow = false;
        setZeroFallback({
          show: localshow,
          message: Zero_ofclient_project_fallbackpage_message[v],
        });
      }
    });
    if (localshow) {
      let data = datacontructor(repodata, parentnameoftree);
      setPageData(data);
    }
  }, [repodata]);
  console.log("repository", pageData, props);
  return (
    <>
      {zeroFallback.show ? (
        <div className={classes.contentBox}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  border: "1px solid #DCDCDC",
                }}
              >
                <Typography className={classes.numbersEdit}>
                  Repository
                </Typography>
                <Divider />
                {/* {pageData?.length > 0?.length > 0 ? ( */}
                <div style={{ padding: "10px 20px 6px 38px" }}>
                  <Tree data={pageData.project_component} />
                </div>
                {/* ) : (
              <div
                style={{ padding: "10px 20px 6px 38px" }}
                className={classes.nodatafound}
              >
                <span>No data found.</span>
              </div>
            )} */}
              </div>
            </Grid>

            {/* -------------------------------- delete ----------------------------------  */}
            {/* <DeleteComponent open={open} deleteClose={handleClose} /> */}
          </Grid>
        </div>
      ) : (
        <FallbackScreenofIDM message={zeroFallback.message} />
      )}
    </>
  );
}

export default withAllContexts(RepositoryMain);
