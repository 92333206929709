import React from "react";
import { withStyles, Tab, Tabs, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { SearchComponent } from "../search";
import { stateProperties } from "../entityComponent/stateProperties";

const styles = (theme) => ({
  root: {
    "& .MuiTab-labelIcon": {
      minHeight: 48,
    },
    "& .::-webkit-scrollbar": {
      height: 2,
    },
    "& .MuiTabs-indicator": {
      // background: 'rgba(26, 216, 197, 1) !important',
      // background: "#45149b",
      height: 3,
    },
  },
  tabs: {
    textTransform: "capitalize",
    fontSize: 13,
    margin: "5px 0px",
    "& .MuiTab-wrapper": {
      display: "contents",
      "& svg": {
        margin: 6,
        fontSize: 17,
      },
    },
  },
  tabsContainer: {
    flex: 1,
    "& .MuiTabs-fixed": {
      overflowX: "auto !important",
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  searchStyle: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px 0px",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={props.padding ? 3 : ""}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
class TabComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      tabtitlestate: [],
    };
  }
  handleChange = (e, v) => {
    this.setState(
      {
        value: v,
      },
      () => {
        this.props.onTabClick(v);
      }
    );
  };

  componentDidMount() {
    const idmtitle = this.props.tabtitles;
    this.setState({ tabtitlestate: idmtitle });
  }

  render() {
    const { classes, tabtitles, padding } = this.props;
    const { value, tabtitlestate } = this.state;
    return (
      <div className={classes.root}>
        <div
          className={classes.flexCenter}
          style={{ borderBottom: "0.5px solid #D6D9E7", padding: "0px 16px" }}
        >
          {" "}
          <Tabs
            value={value}
            onChange={(e, v) => this.handleChange(e, v)}
            className={classes.tabsContainer}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabtitles?.map((v, i) => {
              return (
                <Tab
                  key={i}
                  label={
                    // <Typography
                    //   variant={i === value ? "body1" : "body2"}
                    //   style={{
                    //     fontSize: "0.875rem",
                    //     fontWeight: i === value && "bold",
                    //   }}
                    // >
                    v?.name
                    // </Typography>
                  }
                  //   icon={v?.icon(i === value)}
                  // icon={v?.icon}
                  {...a11yProps(i)}
                  //   style={{ background: i === value && '#fff' }}
                  className={classes.tabs}
                />
              );
            })}
          </Tabs>
          {this.props?.showSearch && (
            <div className={classes.searchStyle}>
              <SearchComponent
                hideSearch
                buttonName={this.props?.buttonName}
                buttonAction={this.props?.buttonAction}
              />
            </div>
          )}
        </div>
        {tabtitles?.map((v, i) => {
          return (
            <TabPanel key={i} value={value} index={i} padding={padding}>
              {this.props[v?.field]}
            </TabPanel>
          );
        })}
      </div>
    );
  }
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default withStyles(styles)(TabComponent);
