import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
  withStyles,
  Typography,
  AppBar,
  Grid,
  Divider,
  Avatar,
  IconButton,
  Popover,
  MenuItem,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Toolbar,
  makeStyles,
  ListItemIcon,
} from "@material-ui/core";
import { LaptopWindows } from "@material-ui/icons";
import { stateProperties } from "../entityComponent/stateProperties";
import {
  projectPaletteInfo,
  validationFieldForProject,
} from "../../utils/constants";
import { isExpired, decodeToken } from "react-jwt";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { TbLogout } from "react-icons/tb";

// style

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme?.zIndex?.drawer + 1,
  },
  appBar: {
    zIndex: theme?.zIndex?.drawer + 1,
    background: theme?.palette?.appBar?.main,
    boxShadow: "unset",
    // borderBottom: "1px solid #e0e0e0",
  },
  title: {
    display: "block",

    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  caption: {
    display: "block",
    marginRight: theme?.spacing(1),
    [theme?.breakpoints?.down("sm")]: {
      fontSize: "0.6rem !important",
    },
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme?.spacing(1),
    marginRight: theme?.spacing(1),
  },
  profilePic: {
    marginLeft: "8px",
  },
  notificationdiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notifications: {},
}));
export var dummy = {
  addproject: false,
  addprojectvalue: "",
  theme: "",
  data: [],
  isopen: false,
  snackmsg: "",
  snacktype: "",
  tab: 0,
  tabIndex: 0,
  qpasData: [],
  addclient: false,
  clientList: [],
  listClient: [],
  status: "client",
  projectTools: [],
  projectToolLists: [],
  headerName: [],
  projectId: "",
  metaDataId: "",
  databaseName: "",
  metaDataName: "",
  userdetails: {},
  clientDrawer: false,
  userDrawer: false,
  username: "",
  email: "",
  designation: "",
  userError: {
    username: false,
    email: false,
  },
  addclientvalue: "",
  clientError: {
    addclientvalue: false,
  },
  clientErrorMsg: {
    addclientvalue: "Please enter the field",
  },
  deleteDrawer: false,

  deleteClient: false,
  deleteClientId: "",
  projectName: "",
  enableEdit: false,
  editProjectId: "",
  anchorEl: null,
  activeStep: 0,
  tabValue: 0,
  themeSwitcher: false,
  selectedFont: false,
  fontFamily: [],
  client_id: "",
  tools: [],
  project_vs_tools: [],
  masterProjectPlatform: [],
  masterLanguage: [],
  projectName: "",
  deleteProject: false,
  language: [],
  themeType: "light",
  client_key: "",
  palette: projectPaletteInfo,
  availableFonts: [],
  masterTypograpy: [],
  cloneMasterTypograpy: [],
  masterFontWeight: [],
  masterFontFamily: [],
  users: [],
  masterUsers: [],
  keyValues: [
    {
      key: "",
      value: "",
    },
  ],
  projectPlatform: [],
  databaseName: "",
  uploadImages: [],
  _idOfproject: "",
  projectPaletteId: "",
  validatation: validationFieldForProject,
  is_update: false,
  validationError: false,
  datavalid: false,
  projectvalid: false,
  gitUiUrl: "",
  gitBinderUrl: "",
  currentclientid: "",
  currentIdmtype: "",
  idmdata: "",
  curreprojectid: "",
  currentroleid: "",
  dynamiclistgenerator_keyvalue: "",
  headeridmdata: {},
  isclientzero: false,
  isprojectzero: false,
  istoolszero: false,
  currentIDMdata: {},
};

function Header(props) {
  const histroy = useHistory();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme?.breakpoints?.down("sm"));
  const [state, setState] = useState({
    ...stateProperties,
    username: "",
    open: false,
    anchorEl: "",
  });
  React.useEffect(() => {
    var token = JSON.parse(sessionStorage.getItem("payload"));
    var decodedtoke = decodeToken(token?.access_token);
    console.log(decodedtoke);
    setState({ ...stateProperties, username: decodedtoke?.given_name });
  }, []);
  const onLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    var keys = Object.keys(dummy);
    var values = Object.values(dummy);
    console.log("lastcheck", stateProperties);
    histroy.push("/login");
    // stateproperties file variables will not erase even when u logout .So we are emptying the all values after logout.
    keys.forEach((v, i) => {
      stateProperties[v] = values[i];
    });
    // stateProperties["headerName"] = ["QDM"];
    console.log("lastcheck1", stateProperties);
  };
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
            <img
              alt="icon"
              src="https://atp.dev.ainqaplatform.in/assets/01_AINQA_Logo_Horizontal_White.svg"
              style={{ width: "100px", height: "auto" }}
            />
            <div>
              <Breadcrumbs separator="›">
                {stateProperties.headerName.map((v, i) => {
                  return (
                    <Typography
                      style={{ color: "white", fontSize: 16 - i }}
                      className={classes.title}
                      variant="body1"
                      noWrap
                    >
                      {v}
                    </Typography>
                  );
                })}
              </Breadcrumbs>
              <Typography
                // className={classes.title}
                variant="caption"
                noWrap
              >
                {process?.env?.REACT_APP_Version}
              </Typography>
            </div>
          </div>

          <div className={classes.grow} />

          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "#fff" }}
          />

          <div className={classes.userProfilecard}>
            <div>
              <Typography
                className={classes.title}
                variant="body1"
                component={"h6"}
                noWrap
              >
                {state.username}
              </Typography>

              {/* <Typography
                // className={classes.title}
                variant="caption"
                noWrap
              >
                {"role"}
              </Typography> */}
            </div>
          </div>
          <Avatar className={classes.profilePic} variant="square">
            {state?.username?.[0]?.toUpperCase()}
          </Avatar>

          {sm ? (
            <IconButton
              style={{ color: "#fff" }}
              onClick={(e) =>
                setState({ ...state, anchorEl: e.currentTarget, open: true })
              }
            >
              <TbLogout />
            </IconButton>
          ) : (
            <Tooltip title="Logout" arrow>
              <IconButton
                style={{ color: "#fff" }}
                onClick={(e) =>
                  setState({ ...state, anchorEl: e.currentTarget, open: true })
                }
              >
                <TbLogout />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        open={state.open}
        anchorEl={state.anchorEl}
        onClose={() => {
          setState({ ...state, anchorEl: null, open: false });
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <List>
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            {!sm && <ListItemText primary="Logout" />}
          </ListItem>
        </List> */}
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Popover>
    </div>
  );
}

export default Header;
