import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const useStyles = makeStyles((theme) => ({
  btnDescription: {
    borderRadius: "10px",
    width: "90px",
    height: "90px",
    display: "block",
  },
  iconStyle: {},
  selectedBtn: {
    border: `1px solid ${theme.palette.primary.main}!important`,
    borderRadius: "10px",
    backgroundColor: "white !important",
    width: "90px",
    height: "90px",
    display: "block",
    color: `${theme.palette.primary.main}`,
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px !important",
  },
  Platformtext: {
    color: `${theme.palette.primary.main}`,
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),

   
    "&:first-child": {
      marginLeft: 0,
    },
    "&:not(:last-child)": {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    "&:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
  },
}))(ToggleButtonGroup);

export default function CustomToggleBtn({ toggleBtns,handleState,projectPlatform }) {
  const classes = useStyles();
  const [items, setItems] = useState([]);

  const handleChange = (event, newPlatform) => {
    console.log(newPlatform);
    handleState('projectPlatform',newPlatform);
  };

  console.log("Something  went wrong", items);

  return (
    <StyledToggleButtonGroup
      className={classes.outline}
      value={projectPlatform}
      onChange={(event,newplat)=>handleChange(event,newplat)}
    >
      {toggleBtns?.length>0 && toggleBtns?.map((toggleBtn) => (
        <ToggleButton
          className={
            projectPlatform.some((item) => item.value === toggleBtn?.value)
              ? classes.selectedBtn
              : classes.btnDescription
          }
          value={toggleBtn}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {toggleBtn?.icon && toggleBtn?.icon(
              projectPlatform.some((item) => item.value === toggleBtn?.value)
                ? "#300e6c"
                : "#bcbcc8"
            )}
            <Typography
              style={{ fontSize: "11px" }}
              className={
                projectPlatform.some((item) => item.value === toggleBtn?.value)
                  ? classes.Platformtext
                  : ""
              }
            >
              {toggleBtn?.label}
            </Typography>
          </div>
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
