import { decodeToken } from "react-jwt";
import { uuid } from "uuidv4";
import { stateProperties } from "../components/entityComponent/stateProperties";
import { Allidmtabvalue, idmType, idmTypeopposite } from "../utils/constants";
export function keyvaluefinder(data) {
  // debugger;
  var finaldata;
  var filteredkey = Object.keys(data).filter((v) => v.endsWith("name"));
  var dublicatenamecheck = Array.isArray(filteredkey);
  if (dublicatenamecheck) {
    filteredkey.forEach((v) => {
      var bool = ["clientname", "projectname", "toolname"].indexOf(v);
      if (bool !== -1) {
        finaldata = v;
      }
    });
  } else {
    finaldata = filteredkey;
  }
  return finaldata;
}

export function datacontructor(mapdata, idmtype_name) {
  debugger;
  let keyvalue = keyvaluefinder(mapdata[0]);
  let finalClientsStructure = [];
  mapdata.forEach((v, index) => {
    let i = {};
    i["name"] = v[keyvalue];
    i["key"] = v[keyvalue].trim().replace(/\s+/g, "_");
    i["id"] = uuid();
    i["_id"] = v._id;
    finalClientsStructure.push(i);
  });
  console.log("lo", finalClientsStructure);
  var repositoryTree = {
    project_component: [
      {
        name: idmtype_name,
        id: uuid(),
        key: idmtype_name.trim().replace(/\s+/g, "_"),
        children: [],
      },
    ],
  };
  repositoryTree["project_component"][0]["children"] = finalClientsStructure;
  return repositoryTree;
}

export function dynamicidmlistgenerator(List, keyheadername) {
  debugger;
  try {
    let keyvalue = keyvaluefinder(List[0]);
    var idmdatas = stateProperties.idmdata;
    var corrected_keyheadername = keyheadername.trim()?.replace(/\s+/g, "_");
    var componentdata =
      idmdatas[0]?.permission?.permission[0]?.repo_mapping?.[
        corrected_keyheadername
      ]?.component;
    var finaldata = [];
    // permissionchecker(idmdatas, corrected_keyheadername);
    List.forEach((v) => {
      // debugger;
      let permissions;
      try {
        let obj = {
          ...v,
          ...componentdata?.[v?.[keyvalue]?.trim()?.replace(/\s+/g, "_")][
            "permission"
          ],
        };
        finaldata.push(obj);
      } catch (err) {
        let permission = {
          read: false,
          write: false,
          update: false,
          delete: false,
        };
        let obj = {
          ...v,
          ...permission,
        };
        finaldata.push(obj);
      }
    });
    console.log("finaldata", finaldata, idmdatas);
    return finaldata;
  } catch (err) {
    console.log({ err });
    return List;
  }
}

export function tokendatagiver(givenvalue) {
  var token = JSON.parse(sessionStorage.getItem("payload"));
  var decodedtoke = decodeToken(token?.access_token);
  var data = decodedtoke?.[givenvalue];
  return data;
}

export function dynamicidmtabvalue(keyheadername, currentpageno) {
  // debugger;
  try {
    // var keyheadername = stateProperties.dynamiclistgenerator_keyvalue;
    var currentpage = currentpageno;
    var currentidmtype = stateProperties.currentIdmtype;
    var Numberofcurrentidmtype = Number(idmTypeopposite[currentidmtype]);
    var idmdata = stateProperties.idmdata;
    var currenttabvalue = Allidmtabvalue[idmType[currentpage]];
    var idmbindedtabvalue;
    var correctedkeyheadername = keyheadername.trim()?.replace(/\s+/g, "_");
    var headeridmdata =
      idmdata[0]?.permission?.permission[0]?.repo_mapping?.[
        correctedkeyheadername
      ].permission;
    stateProperties.headeridmdata = headeridmdata;
    // For eg. if Platform idm  giving permission to remaing tabs for client and  project for this condition (currentpage === idmTypeopposite[currentidmtype])
    if (Number(currentpage) === Numberofcurrentidmtype) {
      const final = ["write", "read", "update", "delete"].every(
        (v, i) => headeridmdata[v]
      );
      if (final) {
        idmbindedtabvalue = currenttabvalue;
        return idmbindedtabvalue;
      } else {
        idmbindedtabvalue = [currenttabvalue[0]];
        return idmbindedtabvalue;
      }
    } else {
      return currenttabvalue;
    }
  } catch (err) {
    return currenttabvalue;
  }
}
function stagefilterchecker(checkfor, id) {
  try {
    let currentstage = stateProperties?.headerName?.length;
    if (checkfor === currentstage && Boolean(id)) {
      return true;
    }
  } catch (err) {
    return false;
  }
}
export function filterproducer(entity, clientid, projectid, rolemanagement) {
  // debugger;
  try {
    let filter = Boolean(rolemanagement)
      ? `${entity}.status==true`
      : `${entity}.activestatus==true`;
    let clientfilter = stagefilterchecker(2, clientid)
      ? `${entity}.clientid=='${clientid}'`
      : "";
    let projectfilter = stagefilterchecker(3, projectid)
      ? `${entity}.projectid=='${projectid}'`
      : "";
    let totalfilter = [filter, clientfilter, projectfilter].filter((v) =>
      Boolean(v)
    );

    return totalfilter.join(" && ");
  } catch (err) {
    return "";
  }
}

export function permissionchecker(idmdatas, keyheadername) {
  debugger;
  try {
    if (stateProperties.currentIdmtype !== "skip") {
      let corrected_keyheadername = keyheadername.trim()?.replace(/\s+/g, "_");
      let componentdata =
        idmdatas[0]?.permission?.permission[0]?.repo_mapping?.[
          corrected_keyheadername
        ]?.component;
      //converting obj in to array
      let idmarray = [];
      Object.keys(componentdata).forEach((v) => {
        idmarray.push(componentdata[v]);
      });
      let finalbool = idmarray.every((v) => {
        // debugger;
        let localbool = Object.keys(v.permission).every(
          (e) => v.permission[e] === false
        );
        console.log({ localbool });
        return localbool;
      });
      console.log({ finalbool });
      return finalbool;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}
