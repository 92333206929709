import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import routes from "./../../router/routes";
import Header from "../../components/navbar/index";
import EntityComponent from "../../components/entityComponent/index";
import AinqaComponent from "../../components/ainqaProject/index";
import { stateProperties } from "../../components/entityComponent/stateProperties";
import { DetailsTwoTone } from "@material-ui/icons";
import {
  clientReadDocument,
  projectReadDocument,
} from "../../function/commonapi";
import { withAllContexts } from "../../hocs";
import config from "../../config";

const styles = (theme) => ({});

class Home extends React.Component {
  // {*----------page inside  child components-----------*}
  constructor(props) {
    super(props);
    this.state = {
      headerName: config.HeaderNameof_ATP,
    };
  }
  handleBackDrop = (open, msg) => {
    this.props.backdrop.setBackDrop({
      open: open,
      message: msg,
    });
  };
  handleSnackBar = (severity, msg) => {
    this.props.alert.setSnack({
      open: true,
      msg,
      severity,
    });
  };
  // async componentDidMount() {
  //   ////debugger;

  // }
  handleHeaderName = (name) => {
    ////debugger;
    this.setState({
      headerName: name,
    });
  };
  giveMeComponentToRender = () => {
    if (
      this.props.location.pathname === routes.home ||
      this.props.match.path === routes.home
    ) {
      return (
        <EntityComponent
          {...this.props}
          handleHeaderName={this.handleHeaderName}
        />
      );
    } else if (
      this.props.location.pathname === routes.ainqa ||
      this.props.match.path === routes.ainqa
    ) {
      return <AinqaComponent {...this.props} />;
    }
  };

  render() {
    const { classes } = this.props;
    console.log({ stateProperties });

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} className={classes.header}>
          {/* Header component here */}
          <Header headerName={this.state.headerName} />
          {/* end */}
        </Grid>

        <Grid item xs={12}>
          {/* // {*----------inside child components-----------*} */}
          {this.giveMeComponentToRender()}
          {/* end */}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withAllContexts(Home)));
