
/*
createdBy:
createdAt:03/03/2022
email:
*/

import {  Button, Typography } from '@material-ui/core';
import React from 'react';
import { styles } from '../homeRouterPageStyle';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "../dialog/index";



const useStyle = makeStyles(styles);


function DeleteCompoent(props){
    const  classes = useStyle();
    const { deleteDrawer,description,handleDeleteDrawerClose,handleDelete } = props;
    return(

        <React.Fragment>
             <Dialog
          open={deleteDrawer}
          transprant={true}

          handleClose={(event) => {handleDeleteDrawerClose();
            event.stopPropagation();
          }
        }
          component={
            <div>
              <div style={{ padding: "10px", textAlign: "center" }}>
                <Typography
                  className={classes.title}
                  style={{ fontSize: "1.2rem" }}
                >
                  {description}
                </Typography>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                  padding: "10px",
                }}
              >
                <Button
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={(event) => {handleDeleteDrawerClose();
                    event.stopPropagation();
                }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {handleDelete();
                    event.stopPropagation();
                }}
                >
                  Submit
                </Button>
              </div>
            </div>
          }
        />
        </React.Fragment>
    )
}

export default DeleteCompoent