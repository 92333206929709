import permission from "../components/permission";
import config from "../config";

export const formSteps = [
  "Project Info",
  "Theme",
  "Access",
  "Initiation Properties",
  "Project Wallet",
];
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export let tabNameValueforclients = [
  {
    name: "Clients",
    field: "clients",
  },
  {
    name: "Users",
    field: "users",
  },
  {
    name: "Roles",
    field: "roles",
  },
  {
    name: "Permission",
    field: "permission",
  },
  {
    name: "Repository",
    field: "repository",
  },
];

export let tabNameValueforprojects = [
  {
    name: "Projects",
    field: "projects",
  },
  {
    name: "Users",
    field: "users",
  },
  {
    name: "Roles",
    field: "roles",
  },
  {
    name: "Permission",
    field: "permission",
  },
  {
    name: "Repository",
    field: "repository",
  },
];
export let tabNameValuefortools = [
  {
    name: "Tools",
    field: "tools",
  },
  {
    name: "Users",
    field: "users",
  },
  {
    name: "Roles",
    field: "roles",
  },
  {
    name: "Permission",
    field: "permission",
  },
  {
    name: "Repository",
    field: "repository",
  },
];

export let Allidmtabvalue = {
  platform: tabNameValueforclients,
  client: tabNameValueforprojects,
  project: tabNameValuefortools,
};
export let colorCliPalette = [
  "#7B241C",
  "#76448A",
  "#1F618D ",
  "#117864",
  "#B9770E ",
  "#1B2631 ",
  "#7B241C",
  "#76448A",
  "#1F618D ",
  "#117864",
  "#B9770E ",
  "#1B2631 ",
];

export let projectPaletteInfo = [
  {
    label: "Background",
    props: [
      { label: "default", color: "#000000" },
      { label: "Paper", color: "#ffffff" },
    ],
  },
  {
    label: "Text",
    props: [
      { label: "Primary", color: "#ffffff" },
      { label: "Secondary", color: "#111113" },
      { label: "Disabled", color: "#111113" },
      { label: "Hint", color: "#111113" },
    ],
  },
  {
    label: "Primary",
    props: [
      { label: "Main", color: "#3f51b5" },
      { label: "Light", color: "#6573c3" },
      { label: "Dark", color: "#2c387e" },
      { label: "Contrast Text", color: "#6573c3" },
    ],
  },
  {
    label: "Secondary",
    props: [
      { label: "Main", color: "#f50057" },
      { label: "Light", color: "#f73378" },
      { label: "Dark", color: "#ab003c" },
      { label: "Contrast Text", color: "#ab003c" },
    ],
  },
  {
    label: "Error",
    props: [
      { label: "Main", color: "#f44336" },
      { label: "Light", color: "#e57373" },
      { label: "Dark", color: "#d32f2f" },
      { label: "Contrast Text", color: "#ab003c" },
    ],
  },
  {
    label: "Warning",
    props: [
      { label: "Main", color: "#ff9800" },
      { label: "Light", color: "#ffb74d" },
      { label: "Dark", color: "#f57c00" },
      { label: "Contrast Text", color: "#000000" },
    ],
  },
  {
    label: "Success",
    props: [
      { label: "Main", color: "#4caf50" },
      { label: "Light", color: "#81c784" },
      { label: "Dark", color: "#388e3c" },
      { label: "Contrast Text", color: "#000000" },
    ],
  },
  {
    label: "Divider",
    props: [{ label: "Divider", color: "#ab003c" }],
  },
];

export let validationFieldForProject = [
  {
    key: "databaseName",
    type: "string",
    msg: "Please Enter Database Name",
    unique: "Database must be unique",
    stepValue: 0,
  },
  {
    key: "projectName",
    type: "string",
    msg: "Please Enter Project Name",
    unique: "project must be unique",
    stepValue: 0,
  },
  {
    key: "projectPlatform",
    type: "array",
    msg: "Please Select Your Project Platform",
    stepValue: 0,
  },
  {
    key: "language",
    type: "array",
    msg: "Please Choose Atleast One Language",
    stepValue: 0,
  },
  // {
  //   key: "gitUiUrl",
  //   type: "string",
  //   msg: "Please Enter Gituiurl Name",
  //   stepValue: 0,
  // },{
  //   key: "gitBinderUrl",
  //   type: "string",
  //   msg: "Please Enter GitBinderUrl Name",
  //   stepValue: 0,
  // },

  {
    key: "keyValues",
    type: "array",
    msg: "Please Fill The Empty Keyvalue field",
    stepValue: 3,
  },
];

export const modifyMasterDetails = (list, lable, value) => {
  let modify_info = list;
  if (modify_info?.length > 0) {
    modify_info = modify_info.map((_) => {
      return {
        ..._,
        label: _[lable],
        value: _[value],
      };
    });
  }
  return modify_info;
};

export const paletteList = [
  {
    label: "Background",
    props: [],
  },
  {
    label: "Text",
    props: [],
  },
  {
    label: "Primary",
    props: [],
  },
  {
    label: "Secondary",
    props: [],
  },
  {
    label: "Error",
    props: [],
  },
  {
    label: "Warning",
    props: [],
  },
  {
    label: "Success",
    props: [],
  },
  {
    label: "Divider",
    props: [],
  },
];

export const entityofidmperson = {
  1: config.Collections_IDM_Person_Platform,
  2: config.Collections_IDM_Person_Client,
  3: config.Collections_IDM_Person_Project,
};

export const entityofidmpermissionmanagement = {
  1: config.Collections_IDM_PermissionManagement_Platform,
  2: config.Collections_IDM_PermissionManagement_Client,
  3: config.Collections_IDM_PermissionManagement_Project,
};
export const entityofidmrole = {
  1: config.Collections_IDM_RoleManagement_Platform,
  2: config.Collections_IDM_RoleManagement_Client,
  3: config.Collections_IDM_RoleManagement_Project,
};
export const entityofidmpermissionrolemapping = {
  1: config.Collections_IDM_PermissionRoleMapping_Platform,
  2: config.Collections_IDM_PermissionRoleMapping_Client,
  3: config.Collections_IDM_PermissionRoleMapping_Project,
};

export const idmType = {
  1: "platform",
  2: "client",
  3: "project",
};
export const idmTypeopposite = {
  platform: 1,
  client: 2,
  project: 3,
};

export const Zero_ofclient_project_fallbackpage_message = {
  isclientzero: "Please add clients to add Permission and view Repository",
  isprojectzero: "Please add Projects to add Permission and view Repository",
  istoolszero: "Please add tools to add Permission and view Repository",
};
console.log({ config }, { entityofidmperson });
