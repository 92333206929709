import React from 'react';
import {
  Redirect,
  Route,
} from "react-router-dom";
import { localStorageKeys } from '../utils';
import routes from './routes';

const PrivateRoute = ({ children, ...rest }) => {
    
  
  const isAuthenticated = () => {
 
    if (sessionStorage.getItem('payload')) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
    {
       isAuthenticated? 
       <Route
       {...rest}
       render={({ location }) =>
        (
           children
         ) 
       }
     /> 
       : 
       <Route
       render={({ location }) =>
        (
          <Redirect
          to={{
            pathname: routes.login,
            state: { from: location }
          }}
        />
         ) 
       }
     /> 
    }
    </>

 
  );
}

export default PrivateRoute;
