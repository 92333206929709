import React from "react";

import {
  withStyles,
  Grid,
  TextField,
  Typography,
  Card,
  Switch,
  Avatar,
  Button,
  IconButton,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Input,
  Chip,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { withAllContexts } from "../../hocs";
const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  root: {
    background: "#f4f4f5",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    height: 40,
    alignSelf: "center",
    margin: "0px 15px",
    backgroundColor: "#eee",
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #0000001A",
    borderRadius: "10px",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 11px rgba(33,33,33,.2)",
      transition: "0.5s",
    },
  },
  containerStyle: {
    padding: theme.spacing(2),
    paddingTop: 0,
    overflowX: "hidden",
  },

  addbtn: {
    color: "#fff",
    background: "#131947",
    borderRadius: 10,
    cursor: "pointer",

    fontSize: 14,
    alignItems: "center",
    padding: "6px 13px 8px 6px",
    display: "flex",
    width: "fit-content",
    float: "right",
    margin: "24px 20px 6px",

    [theme.breakpoints.only("xs")]: {
      margin: "24px 20px 6px",
    },
  },
  dialogbox: {
    padding: 24,
  },
  dialogBox: {
    padding: "24px 24px 0 24px",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    padding: "14px 25px",
    borderBottom: "2px solid #C7CCCC",
  },
  savebtns: {
    color: "#fff",
    background: "#131947",
    borderRadius: 10,
    cursor: "pointer",
    fontSize: 14,
    alignItems: "center",
    padding: "10px 14px",
    width: "83%",
    margin: "0 20px",
    textAlign: "center",
  },
  card: {
    margin: "12px 20px",
    borderRadius: 8,
    boxShadow: "0px 5px 23px #00000014",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    "& .MuiCardHeader-title": {
      fontSize: 15,
      fontWeight: 500,
    },
  },
  choosebtn: {
    background: "#f4f4f5",
    borderRadius: 6,
    padding: "3px 3px",
    margin: "5px 0px",
    textTransform: "capitalize",
    fontSize: 13,
    letterSpacing: 1,
    opacity: 0.8,
    "& a": {
      textDecoration: "unset",
      color: "#222222bf",
    },
  },
  tabTitle: {
    flexGrow: 1,
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: 17,
    letterSpacing: 1,
    [theme.breakpoints.only("xs")]: {
      textAlign: "left",
      fontSize: 16,
      display: "none",
    },
  },
  appbar: {
    background: theme.palette.background.paper,
    boxShadow: "none",
    width: "100%",
    color: "rgba(0, 0, 0, 0.87)",
    borderBottom: "1px solid rgb(0 0 0 / 11%)",
  },
  menulist: {
    color: "rgb(177 176 176)",
    margin: "0px 12px",
    fontWeight: 400,
    padding: 20,
    cursor: "pointer",
    fontSize: 15,
    letterSpacing: 1,
  },
  active: {
    borderBottom: "2px solid #131947",
  },
  backBtn: {
    background: "gray",
    "&:hover": {
      background: "gray",
    },
    color: "white",
    textTransform: "capitalize",
    borderRadius: "8px",
  },
  btnContain: {
    display: "flex",
    justifyContent: "flex-end",
  },
  backDiv: {
    margin: "23px 30px 10px 0px",
  },
  buttonView: {
    boxShadow: "0px 5px 23px #00000014",
    fontSize: 13,
    textTransform: "capitalize",
    letterSpacing: 1,
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  hoverEffect: {
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform 0.8s",
    },
  },
  formControl: {
    marginTop: "12px",
    minWidth: 120,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
});
class AddClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addclientvalue: "",
      clientError: {
        addclientvalue: false,
      },
      clientErrorMsg: {
        addclientvalue: "Please enter the field",
      },
    };
  }
  componentDidMount() {
    this.setState({ addclientvalue: this.props?.editvalues?.clientname ?? "" });
  }
  handleCangeClient = (name, value) => {
    value = value.replace(/[^A-Za-z]/gi, "");
    this.setState({ [name]: value });
  };
  onSubmitClient = () => {
    //debugger;
    let { clientList } = this.props;
    if (
      !this.state?.addclientvalue ||
      this.state?.addclientvalue?.length <= 0
    ) {
      this.setState((prevState) => ({
        ...prevState,
        clientError: {
          addclientvalue: true,
        },
        clientErrorMsg: {
          addclientvalue: "Please enter the field",
        },
      }));
      return;
    } else {
      if (clientList) {
        let check = clientList.some(
          (_client) =>
            _client.clientname.trim().toLowerCase() ===
            this.state?.addclientvalue?.trim().toLowerCase()
        );
        if (check) {
          this.setState((prevState) => ({
            ...prevState,
            clientError: {
              addclientvalue: true,
            },
            clientErrorMsg: {
              addclientvalue: "Client Name already exists",
            },
          }));
          return;
        }
      }

      this.props.onSubmitClient(
        this.state?.addclientvalue,
        this.props?.editvalues?._key
      );
      setTimeout(() => {
        //debugger;
        this.setState({ addclientvalue: "" });
      }, 500);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Typography className={classes.title}>Add Client</Typography>
        <div className={classes.dialogbox}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Client Name"
            label="Client name"
            onChange={(e) =>
              this.handleCangeClient("addclientvalue", e.target.value)
            }
            value={this.state.addclientvalue}
            fullWidth={true}
            error={this.state.clientError.addclientvalue ? true : false}
            helperText={
              this.state.clientError.addclientvalue
                ? this.state.clientErrorMsg.addclientvalue
                : ""
            }
          />
        </div>
        <div className={classes.savebtns} onClick={() => this.onSubmitClient()}>
          {" "}
          Submit{" "}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withAllContexts(AddClient));
