import React from "react";
import { withRouter } from "react-router-dom";
import Users from "../Users";
import Roles from "../Roles";
import RepositoryMain from "../repository";
import PermissionList from "../permission";
import { withStyles } from "@material-ui/core";
import { withAllContexts } from "../../hocs";
import TabComponent from "../tabs";
import { DrawerProps } from "../../utils";
import config from "../../config";
import AddClient from "./addClient";
import { connect } from "react-redux";
import {
  formSteps,
  projectPaletteInfo,
  tabNameValueforprojects,
  tabNameValuefortools,
  tabNameValueforclients,
  entityofidmpermissionrolemapping,
  idmTypeopposite,
  Allidmtabvalue,
  idmType,
  entityofidmrole,
} from "../../utils/constants";
import { stateProperties } from "./stateProperties";
import { styles } from "../homeRouterPageStyle";
import {
  readDocument,
  projectReadDocument,
  clientReadDocument,
  uploadImage,
  bulkUpsertDocument,
  projectIdReadDocument,
  deleteProjectEdgeDocument,
  userfetchfromIDMcollection,
  roledataforperson,
} from "../../function/commonapi";
import { v4 as uuidv4 } from "uuid";
import ClientCompoent from "../Client";
import ToolCompoent from "../Tools";
import ProjectCompoent from "../Projects";
import DeleteCompoent from "../DeleteDialogBox";
import MasterDataFetch from "./masterDataFetch";
import {
  handleSubmitProject,
  handleUpdateProject,
  handleFetchProjectEditData,
} from "./projectCRUDFucntion";
import { dummy } from "../navbar";
import {
  dynamicidmlistgenerator,
  dynamicidmtabvalue,
  permissionchecker,
  tokendatagiver,
} from "../../function/commonfunction";
import Axios from "axios";
import { decodeToken } from "react-jwt";
import FallbackScreenofIDM from "../../screens/FallbackScreenOfIDM";
import { actions } from "atp-idm-binder";
var toolURLwithID = [
  {
    id: config.StratigicModelerToolId,
    url: config.StratigicModelerURL,
  },
  {
    id: config.EnterpriseModelerToolId,
    is_dbdesign: false,
    url: config.EnterpriseModelerURL,
  },
  {
    id: config.QPASBuilderToolId,
    url: config.QPASBuilderURL,
  },
  {
    id: config.EntityDesignerToolId,
    is_dbdesign: true,
    url: config.EntityDesignerURL,
  },
  {
    id: config.MDMDesignerToolId,

    url: config.MDMDesignerURL,
  },
  {
    id: config.UICustomFormDesignerToolId,
    url: config.UICustomFormDesignerURL,
  },
  {
    id: config.UIFormTemplateBuilderToolId,
    url: config.UIFormTemplateBuilderURL,
  },
  {
    id: config.UIAutoRendererToolId,
    url: config.UIAutoRendererURL,
  },
  {
    id: config.UIReportBuilderToolId,
    url: config.UIReportBuilderURL,
  },
  {
    id: config.UIDashboardBuilderToolId,
    url: config.UIDashboardBuilderURL,
  },
  {
    id: config.UIFlowBuilderToolId,
    url: config.UIFlowBuilderURL,
  },
  {
    id: config.RuleBuilderToolId,
    url: config.RuleBuilderURL,
  },
  {
    id: config.ServiceFlowBuilderToolId,
    url: config.ServiceFlowBuilderURL,
  },
  {
    id: config.JobSchedulerToolId,
    url: config.JobSchedulerURL,
  },
  {
    id: config.JobMonitorToolId,
    url: config.JobMonitorURL,
  },

  {
    id: config.ChatbotWorkflowBuilderToolId,
    url: config.ChatbotWorkflowBuilderURL,
  },
  {
    id: config.IntegratedTestEnginToolId,
    url: config.IntegratedTestEnginURL,
  },
  {
    id: config.AlertHubToolId,
    url: config.AlertHubURL,
  },
  {
    id: config.AVCVideoConferencingToolId,
    url: config.AVCVideoConferencingURL,
  },
  {
    id: config.RuntimeAdminConsoleToolId,
    url: config.RuntimeAdminConsoleURL,
  },
  {
    id: config.ELKDashboardToolId,
    url: config.ELKDashboardURL,
  },
  {
    id: config.PromethusDashboardToolId,
    url: config.PromethusDashboardURL,
  },
  {
    id: config.NiFiToolId,
    url: config.NiFiURL,
  },
  {
    id: config.MessageCatalogToolId,
    url: config.MessageCatalogURL,
  },
  {
    id: config.FormConfiguratorsToolId,
    url: config.FormConfiguratorsURL,
  },
  {
    id: config.IDMToolId,
    url: config.IDMURL,
  },
  {
    id: config.UrlshortnerId,
    url: config.Urlshortner,
  },
  {
    id: config.ServiceRegistryId,
    url: config.ServiceRegistryURL,
  },
  {
    id: config.reportviewerId,
    url: config.ReportViewer,
  },
  {
    id: config.EventCatalogId,
    url: config.EventCatalog,
  },
  {
    id: config.Task_Document_Worfklow_Builder_ID,
    url: config.Task_Document_Worfklow_Builder_Url,
  },
  {
    id: config.URLShortenerToolId,
    url: config.URLShortenerURL,
  },
  {
    id: config.PrinterConfigrationToolId,
    url: config.PrinterConfigrationToolURL,
  },
  {
    id: config.RuleBuilderToolID,
    url: config.RulebuilderToolURL,
  },
  {
    id: config.NewFormConfiguratorsToolId,
    url: config.New_Form_configurator,
  },
  {
    id: config.FormRendererToolId,
    url: config.FormRendererURL,
  },
];
class EntityComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...stateProperties, clientName: "", tabvalue: [] };
    console.log("stateProperties1", stateProperties, this.state.clientList);
  }

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  async componentDidMount() {
    debugger;
    var email = tokendatagiver("email");
    this.handleBackDrop(true, "loading");
    const userdetails = await userfetchfromIDMcollection(email);
    stateProperties.currentclientid = userdetails.data?.[0]?.clientid;
    stateProperties.currentIdmtype = userdetails.data?.[0]?.IDM_Type;
    stateProperties.currentprojectid = userdetails.data?.[0]?.projectid;
    stateProperties.currentroleid = userdetails.data?.[0]?.roleid;
    stateProperties.currentIDMdata = userdetails.data?.[0];
    // let entity =
    //   entityofidmrole[idmTypeopposite[stateProperties.currentIdmtype]];
    // let params_db = {
    //   dbname: config.database,
    //   entity: entity,
    //   filter: `${entity}.roleid=='${stateProperties.currentroleid}'`,
    // };
    // let role_list = await this?.props?.roleread(params_db);

    if (stateProperties.currentIdmtype === "skip") {
      this.setState({ header: [config.HeaderNameof_ATP] });
      this.setState({ headerName: [config.HeaderNameof_ATP] });
      stateProperties.headerName = [config.HeaderNameof_ATP];
      let dynamictabs = dynamicidmtabvalue(config.HeaderNameof_ATP, 1);
      this.setState({ idmbinded_tabvalue: dynamictabs });
      var clientdata = await this.getClient();
      this.getMasterInfo();
      this.handleBackDrop(false, "");
      return;
    }

    let entityofuserforrolefetch =
      entityofidmpermissionrolemapping[
        idmTypeopposite[stateProperties.currentIdmtype]
      ];
    let roleid = stateProperties.currentroleid;

    const roledata = await roledataforperson(entityofuserforrolefetch, roleid);
    stateProperties.idmdata = roledata.data.result;

    var IDMtype = stateProperties.currentIdmtype;
    const functions = await this.IDMfunctioncaller(IDMtype);

    this.handleBackDrop(false, "");
  }
  projectidmfunction = async () => {
    debugger;
    var currentprojectid = stateProperties.currentprojectid;
    var returningdataofclient = await this.clientidmfunction();
    let { getclientdata, client_currentname, projectdata, header_array } =
      returningdataofclient;
    console.log(
      "projectidmfunction",
      getclientdata,
      client_currentname,
      projectdata
    );
    var filteredprojectdata = projectdata.filter(
      (v) => v._id === currentprojectid
    );
    // stateProperties.dynamiclistgenerator_keyvalue =
    //   filteredprojectdata[0].projectname;
    this.setState({
      dynamiclistgenerator_keyvalue: filteredprojectdata[0].projectname,
    });
    this.getTools(filteredprojectdata[0], header_array);
    return {
      currentprojectname: filteredprojectdata[0].projectname,
    };
  };
  clientidmfunction = async () => {
    var header = [config.HeaderNameof_ATP];
    var currentclientid = stateProperties.currentclientid;
    var getclientdata = await this.getClient();
    var filtered_current_clientdata = getclientdata?.filter(
      (v) => v._id === currentclientid
    );
    var currentclient_name = filtered_current_clientdata[0]?.clientname;
    var currentclient_id = filtered_current_clientdata[0]?._id;
    header.push(currentclient_name);
    //getting the project
    var project_data = await this.getProject(
      currentclient_id,
      currentclient_name
    );
    this.getMasterInfo();

    return {
      header_array: header,
      projectdata: project_data,
      client_currentname: currentclient_name,
      getclientdata: getclientdata,
    };
  };
  IDMfunctioncaller = async (idmtype) => {
    debugger;
    switch (idmtype) {
      case "platform":
        this.setState({ header: [config.HeaderNameof_ATP] });
        this.setState({ headerName: [config.HeaderNameof_ATP] });
        stateProperties.headerName = [config.HeaderNameof_ATP];
        let dynamiclistgeneratorkeyvalueofplatform = config.HeaderNameof_ATP;
        let dynmaictabvaluesofplatfrom = dynamicidmtabvalue(
          dynamiclistgeneratorkeyvalueofplatform,
          1
        );
        this.setState({
          idmbinded_tabvalue: dynmaictabvaluesofplatfrom,
          dynamiclistgenerator_keyvalue: dynamiclistgeneratorkeyvalueofplatform,
        });
        this.getClient();
        this.getMasterInfo();
        break;
      case "client":
        var clientdata = await this.clientidmfunction();
        //setting the ATP Header
        this.props.handleHeaderName(clientdata.client_currentname);
        //changing to Project
        this.setState({ headerName: clientdata.header_array });
        // dynamic idm tabs
        let dynamiclistgeneratorkeyvalueofclient =
          clientdata.client_currentname;

        let dynmaictabvaluesofclient = dynamicidmtabvalue(
          dynamiclistgeneratorkeyvalueofclient,
          clientdata.header_array.length
        );
        this.setState({
          idmbinded_tabvalue: dynmaictabvaluesofclient,
          dynamiclistgenerator_keyvalue: dynamiclistgeneratorkeyvalueofclient,
        });
        //setting the header
        stateProperties.headerName = clientdata.header_array;
        break;
      case "project":
        debugger;
        await this.projectidmfunction();
        break;
      case "skip":
        debugger;
        this.setState({ header: [config.HeaderNameof_ATP] });
        this.setState({ headerName: [config.HeaderNameof_ATP] });
        stateProperties.headerName = [config.HeaderNameof_ATP];
        var clientdata = await this.getClient();
        this.getMasterInfo();

        break;
      default:
        break;
    }
  };
  getMasterInfo = async () => {
    try {
      let masterFetchData = await MasterDataFetch(this.state);
      ////debugger;
      this.setState((prev) => ({
        ...prev,
        ...masterFetchData.data,
      }));
    } catch (error) {
      this.handleSnackBar("error", "Project Master data fetching error ! ");
    }
  };

  handlefontFamily = (value) => {
    this.setState({ fontFamily: value });
  };

  handleChangeFont = (event, value) => {
    this.setState({ selectedFont: value });
  };

  handleChangeTab = (event, value) => {
    this.setState({ tabValue: value });
  };
  handleSwitcher = (event) => {
    this.setState({ themeSwitcher: event.target.checked });
  };

  async getProject(id, keyname) {
    this.handleBackDrop(true, "loading");
    let payload = {
      _id: id,
      entity: "projects",
    };
    return await projectReadDocument(payload)
      .then((response) => {
        debugger;
        if (response?.data?.Code === 201) {
          if (response?.data?.result?.length > 0) {
            var idmbindedresult = dynamicidmlistgenerator(
              response?.data?.result,
              keyname
            );
            console.log("idmbindedresultforproject", idmbindedresult);
            let fallback = permissionchecker(stateProperties.idmdata, keyname);
            this.setState({
              data: idmbindedresult,
              client_id: id,
              isfallback: fallback,
            });
            stateProperties.currentclientid = id;

            return idmbindedresult;
          } else {
            stateProperties.isprojectzero = true;
            this.setState({ data: [], client_id: id });
            return [];
          }
        } else {
          this.handleSnackBar("error", "something went wrong ! ");
        }
      })
      .catch((err) => {
        this.handleSnackBar("error", "something went wrong ! ");
        console.log("projecterr", err);
      });
    this.handleBackDrop(false, "");
  }

  forwardStep = () => {
    const state = this.state;
    let validation = this.handleValidation();
    if (validation) {
      return;
    }
    this.setState({
      ...state,
      activeStep: this.state.activeStep + 1,
    });

    console.log(state.activeStep);
  };

  backWardStep = () => {
    const state = this.state;

    this.setState({
      ...state,
      activeStep: this.state.activeStep - 1,
    });
  };

  addProject = () => {
    const state = this.state;
    this.setState({
      addproject: !state.addproject,
      enableEdit: false,
      addprojectvalue: "",
      is_update: false,
      theme: "",
      language: [],
      selectUser: "",
      projectName: "",
      language: [],
      themeType: "light",
      palette: projectPaletteInfo,
      availableFonts: [],
      masterTypograpy: state.cloneMasterTypograpy,
      users: [],
      keyValues: [
        {
          key: "",
          value: "",
        },
      ],
      projectPlatform: [],
      databaseName: "",
      uploadImages: [],
      activeStep: 0,
      gitUiUrl: "",
      gitBinderUrl: "",
    });
  };
  onTabClick = (tab) => {
    this.setState({
      ...this.state,
      tabIndex: tab,
      clientList: this.state.listClient,
    });
  };

  switchFunction = () => {
    debugger;
    const { tabIndex } = this.state;
    if (tabIndex === 0) {
      let state = this.state;
      this.setState({
        ...state,
        clientDrawer: true,
      });
      this.addClient();
    } else if (tabIndex === 1) {
      let state = this.state;
      this.setState({
        ...state,
        userDrawer: true,
      });
      this.addUser();
    } else if (tabIndex === 2) {
      this.addRoles();
    }
  };
  editfunc = (values) => {
    debugger;
    this.addClient(values);
    let state = this.state;
    this.setState({
      ...state,
      clientDrawer: true,
    });
  };
  giveMeButtonName = () => {
    const { tabIndex } = this.state;
    if (tabIndex === 0) {
      return "Add Client";
    }
  };
  handleClientDrawer = () => {
    let state = this.state;

    this.setState(
      {
        ...state,
        clientDrawer: false,
      },
      () => {
        this.addClient();
      }
    );
  };

  addClient = (editvalues) => {
    debugger;
    const { drawer, classes } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <AddClient
          onSubmitClient={this.onSubmitClient}
          clientList={this.state.clientList}
          editvalues={editvalues}
        />
      ),
    });
  };

  handleDeleteDrawerClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      deleteDrawer: false,
    }));
  };
  handleDeleteClientDrawerClose = () => {
    this.setState({ deleteClient: false });
  };

  handleAddProjectClose = () => {
    this.setState({
      addProject: false,
      anchorEl: null,
    });
  };

  actionDeleteDrawer = (userId) => {
    this.setState((prevState) => ({
      ...prevState,
      deleteDrawer: true,
      deleteUserId: userId,
    }));
  };

  clientSearch = (keyword) => {
    ////debugger;
    let state = this.state;
    if (keyword === "") {
      let dataList = state.listClient;
      this.setState({ clientList: dataList });
    } else {
      let list = state.listClient;
      let dataLis = [];
      dataLis = list.filter((name) =>
        name.clientname.toLowerCase().includes(keyword.toLowerCase())
      );
      this.setState({ clientList: dataLis });
    }
  };

  deleteClientOpen = (id) => {
    this.setState({ deleteClient: true, client_id: id });
  };

  deleteClient = async () => {
    this.handleBackDrop(true, "loading");
    let params = [
      {
        db_name: config.database,
        entity: "clients",
        is_metadata: false,
        filter: { _id: this.state.client_id },
        doc: {
          activestatus: false,
        },
      },
    ];
    let client_api_value = await bulkUpsertDocument(params);
    if (client_api_value?.data?.Code !== 201) {
      this.handleSnackBar("error", "Client deleted faild ! ");
      this.handleBackDrop(false, "");
      return;
    }
    this.setState((prev) => ({
      ...prev,
      deleteClient: !prev.deleteClient,
    }));
    this.getClient();
    this.handleBackDrop(false, "");
    this.handleSnackBar("success", "Client delete successfully ! ");
  };

  onSubmitClient = async (clientName, key) => {
    this.handleBackDrop(true, "loading");

    let params = [
      {
        db_name: config.database,
        entity: "clients",
        is_metadata: false,
        doc: {
          clientid: uuidv4(),
          clientname: clientName,
          active: true,
          createdby: "",
          activestatus: true,
        },
      },
    ];
    if (Boolean(key)) {
      params[0]["filter"] = { _key: key };
    }
    let client_api_value = await bulkUpsertDocument(params);
    if (client_api_value?.data?.Code !== 201) {
      this.handleSnackBar("error", "Client created faild ! ");
      this.handleBackDrop(false, "");
      return;
    }
    this.getClient();
    this.handleSnackBar(
      "success",
      Boolean(key)
        ? "Client edited successfully ! "
        : "Client created successfully ! "
    );
    const { drawer } = this.props;
    drawer.setDrawer({
      ...drawer,
      open: false,
    });
    this.handleBackDrop(false, "");
  };

  getClient = async () => {
    return await clientReadDocument()
      .then((res) => {
        debugger;
        if (res?.data?.Code === 201) {
          if (res?.data?.result?.length > 0) {
            let state = this.state;
            var idmbinded_clientdata = dynamicidmlistgenerator(
              res.data.result,
              config.HeaderNameof_ATP
            );
            let fallback = permissionchecker(
              stateProperties.idmdata,
              config.HeaderNameof_ATP
            );
            state.isfallback = fallback;
            state.clientList = idmbinded_clientdata;
            state.listClient = idmbinded_clientdata;
            stateProperties.clientList = idmbinded_clientdata;
            stateProperties.listClient = idmbinded_clientdata;
            this.setState((prev) => ({
              ...prev,
              ...state,
            }));
            return idmbinded_clientdata;
          } else {
            stateProperties.isclientzero = true;
          }
        } else {
          this.handleSnackBar("error", "somthing went wrong !");
        }
      })
      .catch((err) => {
        this.handleSnackBar("error", "somthing went wrong !");
      });
  };
  navProject = (id, name, clientUUID) => {
    ////debugger;
    let insertProjectName = this.state.headerName;
    insertProjectName.push(name);
    stateProperties.headerName = insertProjectName;
    ////debugger;
    this.setState({
      status: "project",
      headerName: insertProjectName,
      client_key: clientUUID,
    });
    this.props.handleHeaderName(
      insertProjectName[insertProjectName.length - 1]
    );
    let dynamictabs = dynamicidmtabvalue(
      this.state.dynamiclistgenerator_keyvalue,
      2
    );
    this.setState({ idmbinded_tabvalue: dynamictabs });
    this.getProject(id, this.state.headerName);
    this.props.handleHeaderName(
      insertProjectName[insertProjectName.length - 1]
    );
    this.handleBackDrop(false, "");
  };
  backToClient = () => {
    let insertProjectName = this.state.headerName;
    insertProjectName.pop();
    let tabvalue = dynamicidmtabvalue(
      this.state.dynamiclistgenerator_keyvalue,
      insertProjectName.length
    );
    // for fallback

    stateProperties.isprojectzero = false;
    stateProperties.istoolszero = false;
    this.setState({
      status: "client",
      headerName: insertProjectName,
      idmbinded_tabvalue: tabvalue,
    });

    this.props.handleHeaderName(
      insertProjectName[insertProjectName.length - 1]
    );
    setTimeout(() => {
      this.getClient();
    }, 0);
  };
  handleCangeClient = (n, v) => {
    const state = this.state;
    if ("email" === n || n === "username") {
      if (v.length <= 0) {
        state.userError[n] = true;
      } else {
        state.userError[n] = false;
      }
    }
  };
  handleCange = (n, v) => {
    ////debugger;
    //   add project handle change
    const state = this.state;
    if ("email" === n || n === "username") {
      if (v.length <= 0) {
        state.userError[n] = true;
      } else {
        state.userError[n] = false;
      }
    }
    this.setState(
      {
        ...state,
        [n]: v,
      },
      () => {
        this.addUser();
      }
    );
  };

  getTools = async (metadata, headervalue) => {
    debugger;
    if (typeof metadata === "undefined" || metadata === null) {
      return;
    }
    let insertProjectName = Boolean(headervalue)
      ? headervalue
      : this.state.headerName;
    insertProjectName.push(metadata.projectname);
    stateProperties.currentprojectid = metadata._id;
    stateProperties.headerName = insertProjectName;

    let state = this.state;
    this.setState({
      ...state,
      status: "tools",
      headerName: insertProjectName,
    });
    let dynamictabs = dynamicidmtabvalue(
      this.state.dynamiclistgenerator_keyvalue,
      3
    );
    this.setState({ idmbinded_tabvalue: dynamictabs });
    this.props.handleHeaderName(
      insertProjectName[insertProjectName.length - 1]
    );

    this.props.handleHeaderName(metadata.projectname);

    let toolsPayload = {
      entity: "tools",
      filter: "tools.activestatus==true",
    };
    await readDocument(toolsPayload).then((response) => {
      debugger;
      var toollist = response.data.result;
      var finaltooltoollist = dynamicidmlistgenerator(
        toollist,
        metadata.projectname
      );
      let fallback = permissionchecker(
        stateProperties.idmdata,
        metadata.projectname
      );

      if (stateProperties.currentIdmtype === "project") {
        debugger;
        this.setState({
          tools: finaltooltoollist,
          project_id: metadata._id,
          isfallback: fallback,
        });
      } else {
        this.setState({
          tools: toollist,
          project_id: metadata._id,
        });
      }

      // stateProperties.tools =
      //   stateProperties.currentIdmtype === "project"
      //     ? finaltooltoollist
      //     : toollist,
      // console.log("toolsstate", this.state.tools);
    });
  };
  routerNavigation = async (id, toolid) => {
    debugger;
    console.log("id", toolid, "", id);
    let project_vs_tool = {
      entity: "projectvstools",
      filter: `projectvstools.projectid=='${this.state.project_id}' and projectvstools.toolid=='${id}'`,
    };
    await readDocument(project_vs_tool)
      .then((response) => {
        debugger;

        if (response?.data?.Code === 201) {
          let findURL = toolURLwithID?.filter((_) => _.id === toolid)[0] ?? "";
          let newPageUrl = `${findURL.url}?metadata_id=${response.data.result[0].metadataid}`;
          if (typeof findURL.is_dbdesign === "boolean") {
            newPageUrl = newPageUrl + `&isDB=${findURL.is_dbdesign}`;
          }
          let loginDetails = JSON.parse(sessionStorage.payload);
          // this if condition is only for form configrator asked by kumaravel and panner sir
          if (
            toolid.toString().trim() ===
            "d5d8d81b-1fec-4166-9691-178f998314db".toString().trim()
          ) {
            let repo = [
              {
                name: "Form List",
                route: "/forms",
                component: [
                  { name: "Add Form", uniqueKey: "addForm" },
                  { name: "Copy Form Id", uniqueKey: "copyFormId" },
                  { name: "View Form", uniqueKey: "viewForm" },
                ],
              },
              {
                name: "View Form",
                route: "/manage_form/:id",
                component: [
                  { name: "Add Form/Section", uniqueKey: "addFormSection" },
                  { name: "Add new Question", uniqueKey: "addNewQuestion" },
                  { name: "Preview Form", uniqueKey: "previewForm" },
                ],
              },
            ];
            newPageUrl = newPageUrl + `&repo=${btoa(JSON.stringify(repo))}`;
          }
          newPageUrl = newPageUrl + `&token=${loginDetails.access_token}`;
          window.open(newPageUrl, "_blank");
        } else {
          this.handleSnackBar("error", "somthing went wrong !");
        }
      })
      .catch((error) => {
        this.handleSnackBar("error", "somthing went wrong !");
      });
  };

  handleDeleteDrawerClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      addproject: false,
    }));
  };

  backToProject = () => {
    let popUpheaderName = this.state.headerName;
    popUpheaderName.pop();
    let tabvalue = dynamicidmtabvalue(
      this.state.dynamiclistgenerator_keyvalue,
      popUpheaderName.length
    );
    // for fallback
    stateProperties.istoolszero = false;
    this.setState({
      status: "project",
      headerName: popUpheaderName,
      idmbinded_tabvalue: tabvalue,
    });
    this.props.handleHeaderName(popUpheaderName[popUpheaderName.length - 1]);
  };

  clientColorChanges = (index) => {
    return index % 10;
  };

  //project functions............................ by manikandan.....................

  handleState = (name, value) => {
    if (
      name === "databaseName" &&
      value.length > 1 &&
      !/([A-Za-z]{1}[\w\-]+)$/y.test(value)
    ) {
      return;
    }
    this.setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  handleUploadIcon = async (value) => {
    this.handleBackDrop(true, "uploading");
    let UploadedFiles = [];

    UploadedFiles = await uploadImage(value);
    this.setState((prev) => ({
      ...prev,
      uploadImages: UploadedFiles,
    }));
    this.handleBackDrop(false, "");
  };

  handleObject = (statename, objectkey, objectname, value) => {
    ////debugger;
    let state = this.state;
    if (statename === "palette") {
      state.palette = state.palette.map((_) => {
        if (objectkey === _.label) {
          _.props = _.props.map((_in) => {
            if (objectname === _in.label) {
              _in.color = value;
            }
            return _in;
          });
        }
        return _;
      });
    } else if (statename === "masterTypograpy") {
      state.masterTypograpy = state.masterTypograpy.map((_) => {
        if (objectkey === _.code) {
          _[objectname] = value;
        }
        return _;
      });
    }

    this.setState((prev) => ({
      ...prev,
      ...state,
    }));
  };

  handleValidation = () => {
    let validatation = this.state.validatation;
    let state = this.state;
    let is_error = false;
    if (state.activeStep !== 4) {
      validatation = validatation.filter(
        (_) => _.stepValue === state.activeStep
      );
    }
    validatation.map((_) => {
      ////debugger;
      if (!is_error) {
        if (
          _.type === "string" &&
          (!state[_.key] || state[_.key]?.length <= 0)
        ) {
          is_error = true;
          this.handleSnackBar("error", _.msg);
        } else if (
          _.type === "array" &&
          (!state[_.key] || state[_.key]?.length <= 0)
        ) {
          is_error = true;
          this.handleSnackBar("error", _.msg);
        } else if (_.key === "keyValues") {
          ////debugger;
          for (let i = 0; i < state[_.key].length; i++) {
            let keyvalues = Object.values(state[_.key][i]);
            let lengthofEmpty = keyvalues.filter((_) => !_);
            is_error = lengthofEmpty.length === 1 ? true : false;
            if (is_error) {
              this.handleSnackBar("error", _.msg);
              return;
            }
          }
        } else if (
          _.key === "databaseName" &&
          !/([A-Za-z]{1}[\w\-]+)$/y.test(state[_.key])
        ) {
          is_error = true;
          this.handleSnackBar("error", "database name invalid format");
        }
      }
    });
    return is_error;
  };
  handleDeleteProjectDrawerClose = () => {
    this.setState((prev) => ({
      ...prev,
      deleteProject: !prev.deleteProject,
    }));
  };
  handleDeleteProjectDrawerOpen = (id) => {
    this.setState((prev) => ({
      ...prev,
      deleteProject: !prev.deleteProject,
      _idOfproject: id,
    }));
  };
  handleDeleteProject = async () => {
    this.handleBackDrop(true, "loading");
    let params = [
      {
        db_name: config.database,
        entity: "projects",
        filter: { _id: this.state._idOfproject },
        is_metadata: false,
        doc: {
          activestatus: false,
        },
      },
    ];
    let delete_project = await bulkUpsertDocument(params);
    if (delete_project?.data?.Code !== 201) {
      this.handleSnackBar("error", "delete project faild ! ");
      this.handleBackDrop(false, "");
      return;
    }
    let edgeParams = {
      db_name: config.database,
      entity: "client_project_E",
      filter: `client_project_E._to=='${this.state._idOfproject}'`,
      is_metadata: false,
      doc: {
        activestatus: false,
      },
    };
    let deleteEdge = await deleteProjectEdgeDocument(edgeParams);
    if (deleteEdge?.data?.Code !== 201) {
      this.handleSnackBar("error", "delete project faild in edge collection! ");
      return;
    }
    this.getProject(this.state.client_id);
    this.setState((prev) => ({
      ...prev,
      deleteProject: !prev.deleteProject,
      anchorEl: null,
    }));
    this.handleBackDrop(false, "");
    this.handleSnackBar("success", "delete project successfully ! ");
  };
  submitProject = async () => {
    this.handleBackDrop(true, "loading");
    let validation = this.handleValidation();
    if (validation) {
      this.handleBackDrop(false, "");
      return;
    }
    try {
      const projectSaveRsp = await handleSubmitProject(this.state);
      this.getProject(projectSaveRsp.data.clientId);
      this.setState((prev) => ({
        ...prev,
        addproject: projectSaveRsp.data.addproject,
      }));
      this.handleBackDrop(false, "");
      this.handleSnackBar("success", projectSaveRsp.msg);
    } catch (error) {
      this.handleBackDrop(false, "");
      this.handleSnackBar("error", error.msg);
    }
  };
  //fetch  edit value function .....by manikandan

  fetchProjectEditData = async (id) => {
    this.handleBackDrop(true, "loading");
    try {
      const projectFetchRsp = await handleFetchProjectEditData(this.state, id);
      this.setState((prev) => ({
        ...prev,
        ...projectFetchRsp.data,
        enableEdit: true,
      }));
      this.handleBackDrop(false, "");
      // this.handleSnackBar('success', projectFetchRsp.msg);
    } catch (error) {
      this.handleBackDrop(false, "");
      this.handleSnackBar("error", error.msg);
    }
  };
  //update project ......by manikandan...........
  updateProject = async () => {
    this.handleBackDrop(true, "loading");
    let validation = this.handleValidation();
    if (validation) {
      this.handleBackDrop(false, "");
      return;
    }
    try {
      const projectUpdateRsp = await handleUpdateProject(this.state);
      this.getProject(projectUpdateRsp.data.clientId);
      this.setState((prev) => ({
        ...prev,
        addproject: projectUpdateRsp.data.addproject,
        anchorEl: projectUpdateRsp.data.anchorEl,
      }));
      this.handleBackDrop(false, "");
      this.handleSnackBar("success", projectUpdateRsp.msg);
    } catch (error) {
      this.handleBackDrop(false, "");
      this.handleSnackBar("error", error.msg);
    }
  };
  switchToNextStep = (index) => {
    let validation = this.handleValidation();
    if (validation) {
      return;
    }
    this.setState((prev) => ({
      ...prev,
      activeStep: index,
    }));
  };
  handleBackDrop = (open, msg) => {
    this.props.backdrop.setBackDrop({
      open: open,
      message: msg,
    });
  };
  handleSnackBar = (severity, msg) => {
    this.props.alert.setSnack({
      open: true,
      msg,
      severity,
    });
  };
  render() {
    // console.log("toolsstate", this.state);

    const { classes } = this.props;
    const {
      data,
      clientList,
      status,
      tools,
      deleteClient,
      headerName,
      isfallback,
    } = this.state;
    console.log(this.props);
    return (
      <div className={classes.root}>
        {headerName?.length == 2 ? (
          <>
            <TabComponent
              padding={false}
              onTabClick={this.onTabClick}
              buttonName={this.giveMeButtonName()}
              buttonAction={() => this.switchFunction()}
              tabtitles={this.state.idmbinded_tabvalue}
              projects={
                <ProjectCompoent
                  handleChangeTab={this.handleChangeTab}
                  backToClient={this.backToClient}
                  addProject={this.addProject}
                  handleAddProjectClose={this.handleAddProjectClose}
                  switchToNextStep={this.switchToNextStep}
                  handleSwitcher={this.handleSwitcher}
                  handleState={this.handleState}
                  handleUploadIcon={this.handleUploadIcon}
                  handlefontFamily={this.handlefontFamily}
                  handleObject={this.handleObject}
                  state={this.state}
                  updateProject={this.updateProject}
                  submitProject={this.submitProject}
                  forwardStep={this.forwardStep}
                  backWardStep={this.backWardStep}
                  fetchProjectEditData={this.fetchProjectEditData}
                  handleDeleteProjectDrawerOpen={
                    this.handleDeleteProjectDrawerOpen
                  }
                  clientColorChanges={this.clientColorChanges}
                  handleDeleteProjectDrawerClose={
                    this.handleDeleteProjectDrawerClose
                  }
                  handleDeleteProject={this.handleDeleteProject}
                  formSteps={formSteps}
                  getTools={this.getTools}
                  alert={this.props.alert}
                  data={data}
                  {...this.state}
                  whichidm={headerName.length}
                />
              }
              roles={
                <div style={{ padding: "10px" }}>
                  <Roles whichidm={headerName.length} state={this.state} />
                </div>
              }
              permission={
                <div style={{ padding: "10px" }}>
                  <PermissionList
                    repodata={data}
                    parentnameoftree={headerName[1]}
                    whichidm={headerName.length}
                    state={this.state}
                  />
                </div>
              }
              repository={
                <div style={{ padding: "10px" }}>
                  <RepositoryMain
                    whichidm={headerName.length}
                    repodata={data}
                    parentnameoftree={headerName[1]}
                  />
                </div>
              }
              users={
                <div style={{ padding: "10px" }}>
                  <Users
                    clientList={clientList}
                    projectidm={true}
                    state={this.state}
                    whichidm={headerName.length}
                  />
                </div>
              }
            />
          </>
        ) : (
          ""
        )}
        {headerName?.length == 3 ? (
          <>
            <TabComponent
              padding={false}
              onTabClick={this.onTabClick}
              buttonName={this.giveMeButtonName()}
              buttonAction={() => this.switchFunction()}
              tabtitles={this.state.idmbinded_tabvalue}
              tools={
                <ToolCompoent
                  routerNavigation={this.routerNavigation}
                  backToProject={this.backToProject}
                  tools={tools}
                  whichidm={headerName.length}
                  isfallback={isfallback}
                />
              }
              users={
                <div style={{ padding: "10px" }}>
                  <Users
                    clientList={clientList}
                    whichidm={headerName.length}
                    state={this.state}
                  />
                </div>
              }
              roles={
                <div style={{ padding: "10px" }}>
                  <Roles whichidm={headerName.length} state={this.state} />
                </div>
              }
              permission={
                <div style={{ padding: "10px" }}>
                  <PermissionList
                    repodata={tools}
                    parentnameoftree={headerName[2]}
                    whichidm={headerName.length}
                    state={this.state}
                  />
                </div>
              }
              repository={
                <div style={{ padding: "10px" }}>
                  <RepositoryMain
                    whichidm={headerName.length}
                    repodata={tools}
                    parentnameoftree={headerName[2]}
                  />
                </div>
              }
            />
          </>
        ) : (
          ""
        )}

        {headerName?.length == 1 && (
          <>
            <div>
              <TabComponent
                padding={false}
                onTabClick={this.onTabClick}
                showSearch={
                  this.giveMeButtonName() === "Add Client" ? true : false
                }
                buttonName={this.giveMeButtonName()}
                buttonAction={() => this.switchFunction()}
                tabtitles={this.state.idmbinded_tabvalue}
                clients={
                  <ClientCompoent
                    clientSearch={this.clientSearch}
                    clientList={clientList}
                    deleteClientOpen={this.deleteClientOpen}
                    navProject={this.navProject}
                    clientColorChanges={this.clientColorChanges}
                    editbuttonaction={this.editfunc}
                    isfallback={isfallback}
                  />
                }
                users={
                  <div style={{ padding: "10px" }}>
                    <Users
                      clientList={clientList}
                      whichidm={headerName.length}
                      state={this.state}
                    />
                  </div>
                }
                roles={
                  <div style={{ padding: "10px" }}>
                    <Roles whichidm={headerName?.length} state={this.state} />
                  </div>
                }
                permission={
                  <div style={{ padding: "10px" }}>
                    <PermissionList
                      repodata={clientList}
                      parentnameoftree={headerName[0]}
                      whichidm={headerName.length}
                      state={this.state}
                    />
                  </div>
                }
                repository={
                  <div style={{ padding: "10px" }}>
                    <RepositoryMain
                      whichidm={headerName.length}
                      repodata={clientList}
                      parentnameoftree={headerName[0]}
                    />
                  </div>
                }
              />
              <DeleteCompoent
                deleteDrawer={deleteClient}
                description={"Are you sure, you want to delete this client?"}
                handleDeleteDrawerClose={this.handleDeleteClientDrawerClose}
                handleDelete={this.deleteClient}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, params_db) => {
  return {
    roleread: () => {
      dispatch(actions.ROLE_READ(params_db));
    },
  };
};

export default withStyles(styles)(
  withRouter(
    withAllContexts(connect(null, mapDispatchToProps)(EntityComponent))
  )
);
