import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  keyLabel: {
    "& .MuiFormControl-root": {
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          padding: 6,
        },
      },
    },
  },
  addProperty: {
    marginTop: 16,
  },
}));

const initial = {
  key: "",
  value: "",
};

export default function InitiationProperties(props) {
  const [propertyList, setPropertyList] = useState(props.keyValues);

  useEffect(() => {
    setPropertyList(props.keyValues);
  }, [props.keyValues]);

  const onAddProperty = () => {
    let newProperty = { ...initial };
    let allProperty = [...propertyList];
    for (let i = 0; i < allProperty.length; i++) {
      let keyvalues = Object.values(allProperty[i]);
      let is_error = keyvalues.some((_) => !_);
      if (is_error) {
        props.alert.setSnack({
          open: true,
          msg: "please fill the empty field",
          severity: "error",
        });
        return;
      }
    }
    allProperty.push(newProperty);
    setPropertyList(allProperty.map((x) => x));
  };

  const onChange = (value, key, index) => {
    //debugger
    let allProperty = [...propertyList];
    allProperty[index] = {
      ...allProperty[index],
      [key]: value,
    };
    setPropertyList(allProperty);
    props.handleState("keyValues", allProperty);
  };

  const onDelete = (deletedIndex) => {
    let allProperty = [...propertyList];
    let deletedList = allProperty
      .filter((property, index) => index !== deletedIndex)
      .map((x) => x);
    setPropertyList(deletedList);
    props.handleState("keyValues", deletedList);
  };

  return (
    <div style={{ width: "100%", padding: 24 }}>
      <Grid container item xs={12}>
        {propertyList.map((item, index) => (
          <GenerateNewRow
            item={item}
            index={index}
            hideClose={propertyList?.length === 1}
            onChange={onChange}
            onDelete={onDelete}
          />
        ))}
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onAddProperty}
            style={{ margin: "12px 0px" }}
          >
            + Add Property
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const GenerateNewRow = ({ item, index, hideClose, onChange, onDelete }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography style={{ margin: "20px 0px 4px 0px" }}>{`Property ${
          index + 1
        }`}</Typography>
      </Grid>
      <Grid container item xs={12} spacing={2} alignItems="center">
        <Grid item xs={12} md>
          <TextField
            variant="outlined"
            name={"key"}
            label={"Key"}
            fullWidth
            margin={"dense"}
            value={item?.key}
            onChange={(e) => onChange(e.target.value, "key", index)}
          />
        </Grid>
        <Grid item xs={12} md>
          <TextField
            variant="outlined"
            name={"value"}
            label={"Value"}
            fullWidth
            margin={"dense"}
            value={item?.value}
            onChange={(e) => onChange(e.target.value, "value", index)}
          />
        </Grid>
        <Grid item style={{ minWidth: 24 }}>
          {!hideClose && (
            <IconButton onClick={() => onDelete(index)} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};
