import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { idmTypeopposite } from "../../utils/constants";
export function IDMTotalWrapper(props) {
  var Html = props.children.type;
  let { permissioncheckfor, data } = props;
  console.log("htmlcheck", data?.[permissioncheckfor]);
  return <>{(data?.[permissioncheckfor] ?? true) && props.children}</>;
}

export function IDMHtmlWrapper(props) {
  var Html = props.children.type;
  let { permissioncheckfor, data } = props;
  return <>{(data?.[permissioncheckfor] ?? true) && props.children}</>;
}

export function IDMBackarrow(props) {
  let { idmtype, whichidm } = props;
  var Html = props.children.type;
  var show;
  idmTypeopposite[idmtype] === whichidm ? (show = false) : (show = true);

  return <>{show && props.children}</>;
}
// export default function Privatetag(props) {
//   const [role, setRole] = useState(localStorage.getItem("userrole"));
//   const [bool,setBool]=useState(false)
//   useEffect(() => {
//     let trueorfalse = props.role.some((v) => v === role)
//     setBool(v)
//   },[])
//   return <>{ bool && props.children}</>;
// }

// export default function Privatetagforclient(props) {
//   const [role, setRole] = useState(localStorage.getItem("userrole"));
//   return <>{role === props.role && props.children}</>;
// }

// export default function Privatetagforproject(props) {
//   const [role, setRole] = useState(localStorage.getItem("userrole"));
//   return <>{role === props.role && props.children}</>;
// }

// export default function Privatetagfortools(props) {
//   const [role, setRole] = useState(localStorage.getItem("userrole"));
//   return <>{role === props.role && props.children}</>;
// }
