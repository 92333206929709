/*
createdBy:
createdAt:03/03/2022
email:
*/

import { Avatar, Button, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { styles } from "../homeRouterPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { colorCliPalette } from "../../utils/constants";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { stateProperties } from "../entityComponent/stateProperties";
import { IDMHtmlWrapper, IDMTotalWrapper } from "../Privatetag";
import { dynamicidmlistgenerator } from "../../function/commonfunction";
import FallbackScreenofIDM from "../../screens/FallbackScreenOfIDM";

const useStyle = makeStyles(styles);
var darkColorCli = colorCliPalette;

function ClientCompoent(props) {
  // const [clientState, setClientstate] = useState();
  const {
    clientSearch,
    clientList,
    deleteClientOpen,
    navProject,
    clientColorChanges,
  } = props;
  // console.log({ clientList });
  // React.useEffect(() => {
  //   // if (clientList.length > 0) {
  //   //   var result = dynamicidmlistgenerator(clientList, "QDM");
  //   //   setClientstate(result);
  //   // }
  //   // if (stateProperties.currentIdmtype === "platform") {
  //   //   stateProperties.dynamiclistgenerator_keyvalue = "QDM";
  //   // }
  //   setClientstate(clientList);
  // }, [clientList]);
  const classes = useStyle();
  return (
    <>
      {props.isfallback ? (
        <FallbackScreenofIDM
          message={"Please contact admin for permission mapping"}
        />
      ) : (
        <>
          <Grid container style={{ padding: 16 }}>
            <Grid container justify="flex-end">
              <TextField
                type="text"
                label="Search"
                size="small"
                variant="outlined"
                style={{ width: 300, marginBottom: 8 }}
                placeholder="Search . . ."
                onChange={(e) => clientSearch(e.target.value)}
              />
            </Grid>
            {clientList?.map((v, index) => {
              return (
                <>
                  {
                    // v.read &&
                    <IDMTotalWrapper permissioncheckfor="read" data={v}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={classes.paper}
                        onClick={(event) => {
                          navProject(v?._id, v?.clientname, v?.clientid);
                          event.stopPropagation();
                        }}
                      >
                        <div className={classes.flexCenter}>
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatar}
                            style={{
                              marginRight: 15,
                              textTransform: "capitalize",
                              backgroundColor: `${
                                darkColorCli[clientColorChanges(index)]
                              }`,
                            }}
                          >
                            {v?.clientname.slice(0, 1)}
                          </Avatar>
                          <Typography style={{ textAlign: "center" }}>
                            {v?.clientname}
                          </Typography>
                        </div>
                        <div className={classes.flexCenter}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.buttonView}
                          >
                            <Typography variant="body1">
                              {`${v?.project[0]?.count}`}&nbsp;&nbsp; Projects
                            </Typography>
                            <ChevronRightIcon />
                          </Button>
                          <IDMHtmlWrapper permissioncheckfor="update" data={v}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonView}
                              style={{ marginLeft: 10 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                props.editbuttonaction(v);
                              }}
                            >
                              <Typography variant="body1">Edit</Typography>
                            </Button>
                          </IDMHtmlWrapper>
                          <IDMHtmlWrapper permissioncheckfor="delete" data={v}>
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.buttonView}
                              style={{ marginLeft: 10 }}
                              onClick={(event) => {
                                deleteClientOpen(v?._id);
                                event.stopPropagation();
                              }}
                            >
                              <Typography variant="body1">Delete</Typography>
                            </Button>
                          </IDMHtmlWrapper>
                        </div>
                      </Grid>
                    </IDMTotalWrapper>
                  }
                </>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
}

export default ClientCompoent;
