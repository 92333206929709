/*
createdBy:
createdAt:03/03/2022
email:mani@crayond.co
*/
import { readDocument } from "../../function/commonapi";
import { modifyMasterDetails } from "../../utils/constants";

const MasterDataFetch = (state) => {
  return new Promise(async (resolve, reject) => {
    let modifyData = {
      // tools: [],
      masterUsers: [],
      masterProjectPlatform: [],
      masterLanguage: [],
      masterTypograpy: [],
      cloneMasterTypograpy: [],
      masterFontWeight: [],
      masterFontFamily: [],
    };
    try {
      let platform = {
        entity: "platformconfig",
        filter: "platformconfig.activestatus==true",
      };
      let language_mst = {
        entity: "generalmaster",
        filter:
          "generalmaster.gentype=='LANG' and generalmaster.activestatus==true",
      };
      let typograpy_mst = {
        entity: "generalmaster",
        filter:
          "generalmaster.gentype=='TYPOGRAPY' and generalmaster.activestatus==true",
      };
      let fontweight_mst = {
        entity: "generalmaster",
        filter:
          "generalmaster.gentype=='FONTWEIGHT' and generalmaster.activestatus==true",
      };
      let fontfamily_mst = {
        entity: "generalmaster",
        filter:
          "generalmaster.gentype=='FONTFAMILY' and generalmaster.activestatus==true",
      };
      let users_mst = {
        entity: "users",
        filter: "users.activestatus==true",
      };
      let toolsPayload = {
        entity: "tools",
        filter: "tools.activestatus==true",
      };
      await readDocument(toolsPayload).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          modifyData.tools = response.data.result;
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });

      await readDocument(users_mst).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          let modify_info = response.data.result;
          if (modify_info?.length > 0) {
            modify_info = modifyMasterDetails(modify_info, "email", "_id");
          }
          modifyData.masterUsers = modify_info;
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });
      await readDocument(platform).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          let modify_info = response.data.result;
          if (modify_info?.length > 0) {
            modify_info = modifyMasterDetails(modify_info, "platform", "_id");
          }
          modifyData.masterProjectPlatform = modify_info;
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });
      await readDocument(language_mst).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          let modify_info = response.data.result;

          if (modify_info?.length > 0) {
            modify_info = modifyMasterDetails(
              modify_info,
              "description",
              "_id"
            );
          }
          modifyData.masterLanguage = modify_info;
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });
      await readDocument(typograpy_mst).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          let modify_info = response.data.result;

          modifyData.masterTypograpy = modify_info.map((_) => {
            return {
              ..._,
              fontsize: "",
              fontweight: "",
              linehgt: "",
              ltrspace: "",
              typofonts: "",
            };
          });
          modifyData.cloneMasterTypograpy = JSON.parse(
            JSON.stringify(modifyData.masterTypograpy)
          );
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });
      await readDocument(fontweight_mst).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          let modify_info = response.data.result;

          if (modify_info?.length > 0) {
            modify_info = modifyMasterDetails(
              modify_info,
              "description",
              "_id"
            );
          }
          modifyData.masterFontWeight = modify_info;
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });
      await readDocument(fontfamily_mst).then((response) => {
        //debugger;
        if (response?.data?.Code === 201) {
          let modify_info = response.data.result;

          if (modify_info?.length > 0) {
            modify_info = modifyMasterDetails(
              modify_info,
              "description",
              "_id"
            );
          }
          modifyData.masterFontFamily = modify_info;
        } else {
          reject({
            error: true,
            msg: "something went wrong ! ",
            data: modifyData,
          });
        }
      });

      resolve({
        error: false,
        msg: "",
        data: modifyData,
      });
    } catch (error) {
      reject({
        error: true,
        msg: error,
        data: modifyData,
      });
    }
  });
};

export default MasterDataFetch;
