import { Grid, Typography } from "@material-ui/core";
import React from "react";

function FallbackScreenofIDM(props) {
  return (
    <div>
      <Grid container>
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          xl={12}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <Typography align="center" variant="h5" style={{ padding: "10px" }}>
            {props.message}
          </Typography>{" "}
        </Grid>
      </Grid>
    </div>
  );
}

export default FallbackScreenofIDM;
