import React, { useState, useEffect, useRef } from "react";
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Router, useHistory } from "react-router-dom";
import routes from "../../router/routes";
import Config from "../../config";
import axios from "axios";
import config from "../../config";
import { stateProperties } from "../../components/entityComponent/stateProperties";
import {
  entityofidmpermissionrolemapping,
  idmTypeopposite,
} from "../../utils/constants";
const useStyles = makeStyles((theme) => ({
  textInput: {
    height: 35,
  },
  fullHeight: {
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#F3F6FF",
  },
  columnSection: {
    justifyContent: "center",
    display: "flex",
    width: 370,
    background: "#fff",
    padding: 18,
    borderRadius: "12px",
    boxShadow: "0px 5px 23px #00000014",
  },
  headerSection: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    marginBottom: 50,
    marginTop: 4,
  },
  rowView: {
    marginBottom: 20,
  },
  logo: {
    width: 50,
    height: 50,
    borderRadius: 100,
    marginRight: 20,
  },
}));

export default function Login(props) {
  const classes = useStyles(props);

  const [state, setState] = useState({
    email_id: "",
    password: "",
    error: {
      email_id: "",
      password: "",
    },
    message: "",
    idmtype: "",
  });
  const roleid = useRef("");
  useEffect(() => {
    if (sessionStorage.getItem("payload")) {
      props.history.push("/home");
    }
  }, []);
  const history = useHistory();
  const validate = () => {
    let isValidated = true;

    if (!state.email_id) {
      isValidated = false;
      state.error.email_id = "Enter email id";
    }

    if (!state.password) {
      isValidated = false;
      state.error.password = "Enter password";
    }

    setState({ ...state });
    return isValidated;
  };

  const handleLogin = async () => {
    localStorage.setItem("clientdetailsofperson", "");
    localStorage.setItem("isclient", false);
    localStorage.setItem("userrole", "");
    localStorage.setItem("projectid", "");
    if (!validate()) {
      return false;
    }
    stateProperties.email = state.email_id;
    // If the mail id has isclient true in IDM_person collection
    // await axios
    //   .post(`${config.entity_api_url}/api/execute_aql`, {
    //     db_name: `${config.idmDatabase}`,
    //     query: `let client=(for IDM_Person_Client in IDM_Person_Client filter IDM_Person_Client.email== '${state.email_id}' && IDM_Person_Client.activestatus==true return IDM_Person_Client) let platform=(for IDM_Person_Platform in IDM_Person_Platform filter IDM_Person_Platform.email == '${state.email_id}' && IDM_Person_Platform.activestatus==true return IDM_Person_Platform) let Projects=(for IDM_Person_Project in IDM_Person_Project filter IDM_Person_Project.email== '${state.email_id}' && IDM_Person_Project.activestatus==true return IDM_Person_Project) return contains(client,'${state.email_id}')?first(client):contains(platform,'${state.email_id}')?first(platform):contains(Projects,'${state.email_id}')?first(Projects):false`,
    //   })
    //   .then(async (response) => {
    //     //debugger;
    //     if (response.data.length > 0) {
    //       roleid.current = response.data[0].roleid;
    //       stateProperties.currentclientid = response.data[0].clientid;
    //       stateProperties.currentIdmtype = response.data[0].IDM_Type;
    //       stateProperties.currentprojectid = response.data[0].projectid;
    //     }
    //   })
    //   .catch((err) => {});
    // await axios
    //   .post(`${config.entity_api_url}/api/read_documents`, {
    //     db_name: `${config.idmDatabase}`,
    //     entity:
    //       entityofidmpermissionrolemapping[
    //         idmTypeopposite[stateProperties.currentIdmtype]
    //       ],
    //     filter: ` ${
    //       entityofidmpermissionrolemapping[
    //         idmTypeopposite[stateProperties.currentIdmtype]
    //       ]
    //     }.role_id=='${roleid.current}'`,
    //     return_fields: `${
    //       entityofidmpermissionrolemapping[
    //         idmTypeopposite[stateProperties.currentIdmtype]
    //       ]
    //     }`,
    //   })
    //   .then((res) => {
    //     //debugger;
    //     stateProperties.idmdata = res.data.result;
    //     console.log("stateonlogin", stateProperties);
    //   })
    //   .catch((err) => {
    //     alert("there is an error");
    //   });
    //debugger;
    let params = {
      username: state.email_id,
      password: state.password,
    };

    var myHeaders = { "Content-Type": "text/plain" };
    var encoded = btoa(JSON.stringify(params));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      data: encoded,
      redirect: "follow",
      url: `${Config.nifi_api}/api/v1/atpsignin`,
    };

    let keyClock = await axios(requestOptions).catch((error) => {
      setState({
        ...state,
        message: "Invalid Credentials",
      });
    });
    if (!keyClock?.data?.access_token) {
      setState({
        ...state,
        message: "Invalid Credentials",
      });
      return;
    }
    // admin login is created via keycloak so,there is no entry in idm person collection
    // this if condition is for back arrow functionality in superadmin login
    // this will work only for "admin-user@qdmadminconsole.com" this email that is created via keycloack
    if (state.email_id === "admin-user@qdmadminconsole.com") {
      localStorage.setItem("userrole", "superadmin");
    }
    sessionStorage.setItem("payload", JSON.stringify(keyClock.data));
    history.push(routes.home);
  };

  const keydownFunc = (event, sectionId) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };
  const handleCLientCheck = (e) => {
    ////debugger;
  };
  return (
    <div className={classes.fullHeight}>
      <Grid container className={classes.columnSection}>
        <Grid>
          <img
            src="/images/icons/logo.png"
            alt="logo"
            style={{
              height: "50px",
              width: "50px",
              "object-fit": "contain",
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.headerSection}>
          <Typography variant="h4">QDM Dev Studio</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.rowView}>
            <TextField
              fullWidth
              label="Email Id"
              variant="standard"
              value={state.email_id}
              placeholder={"ide@example.com"}
              error={state.error.email_id}
              helperText={state.error.email_id}
              onBlur={(e) => handleCLientCheck(e)}
              onChange={(e) =>
                setState({
                  ...state,
                  email_id: e.target.value,
                  error: { ...state.error, email_id: "" },
                  message: "",
                })
              }
            />
          </div>

          <div className={classes.rowView}>
            <TextField
              fullWidth
              variant="standard"
              label="Password"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                className: classes.textInput,
              }}
              value={state.password}
              placeholder={"Password"}
              error={state.error.password}
              onKeyDown={(e) => keydownFunc(e)}
              helperText={state.error.password}
              onChange={(e) =>
                setState({
                  ...state,
                  password: e.target.value,
                  error: { ...state.error, password: "" },
                  message: "",
                })
              }
            />
          </div>

          <div>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              color="error"
            >
              {state.message}
            </Typography>
          </div>

          <div className={classes.rowView}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleLogin}
            >
              Log In
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
